const IMAGES = {
  CompanyLogo: require("./GAlogo.svg").default,
  DoctorTeam: require("./doctorTeam.svg").default,
  DoctorIcon1: require("./doctor.svg").default,
  DoctorIcon2: require("./doctor_primary.svg").default,
  LogoutIcon1: require("./logout.svg").default,
  LogoutIcon2: require("./logout_primary.svg").default,
  GraphIcon1: require("./graph.svg").default,
  GraphIcon2: require("./graph_primary.svg").default,
  ImageIcon1: require("./image-search.svg").default,
  ImageIcon2: require("./image-search-primary.svg").default,
};

export default IMAGES;
