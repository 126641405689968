import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  MASTER_SERVICE_API_URL,
  MASTER_SERVICE_TOKEN,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import {
  Organization,
  OrganizationResponse,
} from "../../../domain/models/organization-response";
import { RemoteFetchOrganizations } from "../../../data/usecases/remote-fetch-organizations";

interface fetchOrganizationsSliceInterface {
  fetchOrganizations: Function;
  organizations: Organization[];
  fetchOrganizationsState: FetchState;
}

const initialState = {
  organizations: [],
  fetchOrganizationsState: FetchState.DEFAULT,
};

export const useFetchOrganizationsSlice: StateCreator<
  fetchOrganizationsSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    fetchOrganizations: async () => {
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${MASTER_SERVICE_TOKEN}`,
      });

      const fetchOrganizations = new RemoteFetchOrganizations(
        `${MASTER_SERVICE_API_URL}${Endpoints.FETCH_ORGANIZATIONS}`,
        axiosHttpClient
      );

      try {
        set({ fetchOrganizationsState: FetchState.LOADING });

        let result: OrganizationResponse = await fetchOrganizations.fetch();
        if (result.success) {
          set({ organizations: result.data });

          set({ fetchOrganizationsState: FetchState.SUCCESS });
        }
      } catch (err) {
        set({ fetchOrganizationsState: FetchState.ERROR });
      }
    },
  };
};
