import { Box, Skeleton, Stack } from "@mui/material";

const ImageSkeleton = () => {
  return (
    <div>
      <Box>
        <Skeleton height={250} width={400} />
        <Stack direction={"row"} justifyContent={"space-evenly"}>
          <Skeleton height={50} width={80} />
          <Skeleton height={50} width={80} />
          <Skeleton height={50} width={80} />
        </Stack>
        <Skeleton height={50} width={400} />
      </Box>
    </div>
  );
};

export default ImageSkeleton;
