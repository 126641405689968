import { create } from "zustand";
import { useFetchDoctorsSlice } from "../slice/doctor/fetchDoctorDetailsSlice";
import { useFetchDoctorByIdSlice } from "../slice/doctor/fetchDoctorByIdSlice";
import { useVerifyDoctorFieldSlice } from "../slice/doctor/verifyDoctorFieldsSlice";

interface DoctorStoreInterface
  extends ReturnType<typeof useFetchDoctorsSlice>,
    ReturnType<typeof useFetchDoctorByIdSlice>,
    ReturnType<typeof useVerifyDoctorFieldSlice> {}

export const useDoctorStore = create<DoctorStoreInterface>((...data) => ({
  ...useFetchDoctorsSlice(...data),
  ...useFetchDoctorByIdSlice(...data),
  ...useVerifyDoctorFieldSlice(...data),
}));
