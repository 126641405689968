import Moment from "moment";

export function getDatesInRange(startDate: any, endDate: any) {
  const date = new Date(startDate.getTime()) as any;

  const dates: any = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export const handleGetFullDateWithoutTime = (date: any) => {
  Moment.locale("utc");
  return Moment(date).format("DD MMMM YYYY");
};
