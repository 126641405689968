import React from "react";
import { Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import CenteredStack from "../../../ga-components/centered-stack";
import { ActivStatus } from "../../../../domain/enums/active-status";

interface Props {
  detailsHeader: string;
  subHeading?: string;
  status?: string;
}
const DoctorDetailsDynamicHeader: React.FC<Props> = ({
  detailsHeader,
  status,
}) => {
  return (
    <>
      <CenteredStack>
        <Typography fontSize={"1.25rem"} fontWeight={700} color="GrayText">
          {detailsHeader}
        </Typography>
        {status === ActivStatus.ACTIVE && (
          <CenteredStack sx={{ mt: 1 }}>
            <TaskAltIcon color="success" />
            <Typography
              textTransform={"uppercase"}
              color="green"
              fontWeight={600}
              fontSize={"0.875rem"}
              component="span"
            >
              Active
            </Typography>
          </CenteredStack>
        )}
        {status === ActivStatus.IN_ACTIVE && (
          <CenteredStack sx={{ mt: 1, color: "red" }}>
            <CancelIcon color="error" />
            <Typography
              textTransform={"uppercase"}
              fontWeight={600}
              fontSize={"0.875rem"}
            >
              Inactive
            </Typography>
          </CenteredStack>
        )}
      </CenteredStack>
    </>
  );
};

export default DoctorDetailsDynamicHeader;
