import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 2 },
};

interface Props {
  children: any;
}

const Carousel: React.FC<Props> = ({ children }) => (
  <AliceCarousel
    items={children}
    responsive={responsive}
    controlsStrategy="alternate"
    disableDotsControls
  />
);

export default Carousel;
