import { create } from "zustand";
import { useFetchImageTypesSlice } from "../slice/doctor-profiler-verification/fetchImageTypesSlice";
import { useUpdateImageTypesSlice } from "../slice/doctor-profiler-verification/updateImageTypeSlice";
import { useDoctorProfilerVerificationCommonSlice } from "../slice/doctor-profiler-verification/commonSlice";
import { useFetchImageRejectionReasonsSlice } from "../slice/doctor-profiler-verification/fetchImageRejectionReasonSlice";
import { useRejectImageSlice } from "../slice/doctor-profiler-verification/rejectImageSlice";
import { useFetchUnverifiedImagesSlice } from "../slice/doctor-profiler-verification/fetchUnverifiedImagesSlice";
import { useVerifyBulkImagesSlice } from "../slice/doctor-profiler-verification/verifyBulkImagesSlice";

interface DoctorProfilerVerificationInterface
  extends ReturnType<typeof useFetchImageTypesSlice>,
    ReturnType<typeof useUpdateImageTypesSlice>,
    ReturnType<typeof useDoctorProfilerVerificationCommonSlice>,
    ReturnType<typeof useFetchImageRejectionReasonsSlice>,
    ReturnType<typeof useRejectImageSlice>,
    ReturnType<typeof useFetchUnverifiedImagesSlice>,
    ReturnType<typeof useVerifyBulkImagesSlice> {}

export const useDoctorProfilerVerification =
  create<DoctorProfilerVerificationInterface>((...data) => ({
    ...useFetchImageTypesSlice(...data),
    ...useUpdateImageTypesSlice(...data),
    ...useDoctorProfilerVerificationCommonSlice(...data),
    ...useFetchImageRejectionReasonsSlice(...data),
    ...useRejectImageSlice(...data),
    ...useFetchUnverifiedImagesSlice(...data),
    ...useVerifyBulkImagesSlice(...data),
  }));
