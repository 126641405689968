import { UpdateImageType } from "../../../../domain/models/data-entry/updateImageTypes";
import {
  UpdateImageTypeParams,
  UpdateImageTypes,
} from "../../../../domain/usages/data-entry/doctor-verification/update-image-types";
import { HttpConstants } from "../../../protocols/http/http-constants";
import { HttpPutClient } from "../../../protocols/http/http-put-client";

export class RemoteUpdateImageType implements UpdateImageTypes {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}
  async update(
    doctorId: string,
    imageId: string,
    params?: UpdateImageTypeParams.Params
  ): Promise<UpdateImageType> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", doctorId).replace(":imageId", imageId),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.data;
  }
}
