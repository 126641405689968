import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemoteUpdateImageType } from "../../../data/usecases/data-entry/doctor-verification/remote-update-image-type";
import { UpdateImageTypeParams } from "../../../domain/usages/data-entry/doctor-verification/update-image-types";
import Swal from "sweetalert2";

interface updateImageTypesSliceInterface {
  updateImageTypes: Function;
  updateImageTypesState: FetchState;
}

const initialState = {
  updateImageTypesState: FetchState.DEFAULT,
};

export const useUpdateImageTypesSlice: StateCreator<
  updateImageTypesSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    updateImageTypes: async (
      doctorId: string,
      imageId: string,
      type: string,
      comment?: string
    ) => {
      const storage = LocalJsonStorage.getInstance();
      const token = storage.get(AUTH_TOKEN);
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
      });

      const updateImageTypes = new RemoteUpdateImageType(
        `${DOCTOR_SERVICE_API_URL}${Endpoints.UPDATE_IMAGE_TYPES}`,
        axiosHttpClient
      );

      let payload: UpdateImageTypeParams.Params = {
        type,
      };

      if (comment) {
        payload.comment = comment;
      }

      try {
        set({ updateImageTypesState: FetchState.LOADING });

        let result = await updateImageTypes.update(doctorId, imageId, payload);
        if (result.success) {
          set({ updateImageTypesState: FetchState.SUCCESS });
          Swal.fire({
            title: result.data.message,
            icon: "success",
            timer: 800,
            showConfirmButton: false,
          });
        }
      } catch (err) {
        set({ updateImageTypesState: FetchState.ERROR });
        Swal.fire("Internal server error", "", "error");
      }
    },
  };
};
