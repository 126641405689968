import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ImageRejectionReasonEntity } from "../../../../domain/models/image-rejection-reasons";
import { useDoctorProfilerVerification } from "../../../../store/main/DoctorProfilerVerificationStore";
import FetchState from "../../../../domain/enums/fetch-state-type";

type Props = {
  open: boolean;
  handleClose: Function;
  doctorId: string;
  imageId: string;
  handleCallBack: Function;
};

const RejectImageModal: React.FC<Props> = ({
  open,
  handleClose,
  doctorId,
  imageId,
  handleCallBack,
}) => {
  const [reasonCode, setReasonCode] = useState<string>("");

  const {
    fetchImageRejectionReasons,
    imageRejectionReasons,
    rejectImage,
    rejectImageState,
  } = useDoctorProfilerVerification();

  useEffect(() => {
    fetchImageRejectionReasons();
  }, []);

  const handleConfirm = async () => {
    let params = {
      reasonCode: reasonCode,
    };
    await rejectImage(doctorId, imageId, reasonCode);
    if (rejectImageState === FetchState.SUCCESS) {
      handleClose();
      handleCallBack(doctorId, imageId, params);
    } else if (rejectImageState === FetchState.ERROR) {
      handleClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogContent>
          <>
            <div
              style={{
                width: "427px",
                height: "343",
                padding: "10px 0px 0px 4px",
              }}
            >
              <Typography color="inherit" fontWeight={24}>
                Reject
              </Typography>
              <div style={{ padding: "10px 0px 0px 10px" }}>
                <RadioGroup>
                  {imageRejectionReasons?.reasons?.map(
                    (reason: ImageRejectionReasonEntity) => {
                      return (
                        <FormControlLabel
                          key={reason.code}
                          onChange={() => setReasonCode(reason.code)}
                          label={
                            <Typography>
                              {reason.message.toUpperCase()}
                            </Typography>
                          }
                          control={
                            <Radio value={reason.code} color="warning" />
                          }
                        />
                      );
                    }
                  )}
                </RadioGroup>
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <>
            <div style={{ paddingBottom: "15px", paddingRight: "23px" }}>
              <Button
                disabled={!reasonCode ? true : false}
                variant="contained"
                color={reasonCode ? "warning" : "info"}
                onClick={handleConfirm}
              >
                <Typography color="inherit">"Reject Image"</Typography>
              </Button>
            </div>
          </>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RejectImageModal;
