import { StateCreator } from "zustand";

interface updateImageTypesSliceInterface {
  disableSubmitButton: boolean;
  setDisableSubmitButton: Function;
}

const initialState = {
  disableSubmitButton: true,
};

export const useDoctorProfilerVerificationCommonSlice: StateCreator<
  updateImageTypesSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    setDisableSubmitButton: (value: boolean) => {
      set({ disableSubmitButton: value });
    },
  };
};
