import { Grid, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import DoctorDetailsHeader from "./common/doctor-details-header";
import DoctorDetailsDynamicHeader from "./common/doctor-details-dynamic-header";
import VerificationTextField from "../common/textfield/VerificationTextField";
import Carousel from "./common/carousel";
import Status from "../../../domain/enums/status";
import { handleGetFullDateWithoutTime } from "../../../utils/DateGetter";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import { Name } from "../../../domain/models/doctor/name";
import { Contacts } from "../../../domain/models/doctor/contacts";
import { Address } from "../../../domain/models/doctor/address";
import { DoctorQualification } from "../../../domain/models/doctor/qualification";
import { DoctorSpeciality } from "../../../domain/models/doctor/speciality";
import { useDoctorStore } from "../../../store/main/DoctorStore";
import ConfirmApprove from "../data-entry/doctorProfilerVerifcation/ConfirmApprove";

interface Props {
  doctorDetails: DoctorData;
}
const DoctorPersonalDetails: React.FC<Props> = ({ doctorDetails }) => {
  const { verifyDoctorField } = useDoctorStore();

  const [showVerificationDialog, setShowVerificationDialog] =
    useState<boolean>(false);
  const [verificationFieldDetails, setVerificationFieldDetails] = useState<{
    fieldId: string;
    fieldName: string;
  } | null>(null);

  const handleChange = () => {};

  const handleCloseVerificationDialog = () => {
    setShowVerificationDialog(false);
    setVerificationFieldDetails(null);
  };

  const handleOpenVerificationDialog = (fieldId: string, fieldName: string) => {
    setVerificationFieldDetails({ fieldId, fieldName });
    setShowVerificationDialog(true);
  };

  const handleVerifyDoctorField = () => {
    if (verificationFieldDetails) {
      const { fieldId, fieldName } = verificationFieldDetails;
      verifyDoctorField(doctorDetails._id, fieldId, fieldName);
      handleCloseVerificationDialog();
    }
  };

  return (
    <>
      {doctorDetails?.fullNames?.length ? (
        <>
          <DoctorDetailsDynamicHeader detailsHeader="Doctor Names" />
          <Box sx={{ borderBottom: "1px solid #f0f0f0", mb: 3 }}>
            <Carousel>
              {doctorDetails?.fullNames?.map(
                (fullName: Name, index: number) => (
                  <Box
                    key={index}
                    px={2}
                    sx={{ borderRight: "1px solid #f0f0f0" }}
                  >
                    <Typography fontWeight={700}>#{`${index + 1}`}</Typography>

                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12}>
                        <VerificationTextField
                          name={`${fullName.name}`}
                          handleChange={handleChange}
                          value={fullName?.name}
                          label="Doctor Name:"
                          isVerified={
                            fullName?.verification?.status === "verified"
                          }
                          fieldName="name"
                          fieldId={fullName._id}
                          handleVerification={handleOpenVerificationDialog}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <VerificationTextField
                          name={"verificationSources"}
                          handleChange={handleChange}
                          value={fullName?.verification?.verificationSources
                            ?.map((v) => v.source)
                            .join(", ")}
                          label="Verification Source:"
                        />
                      </Grid>
                      {fullName?.verification?.verificationSources ? (
                        <Grid item xs={12}>
                          <VerificationTextField
                            name={`verificationSources`}
                            handleChange={handleChange}
                            value={`${fullName?.verification?.verificationSources
                              ?.map((v) =>
                                v.verifiedOn
                                  ? handleGetFullDateWithoutTime(v.verifiedOn)
                                  : "-"
                              )
                              .join(", ")}`}
                            label="Verified On:"
                            sx={{ textTransform: "capitalize" }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                )
              )}
            </Carousel>
          </Box>
        </>
      ) : null}

      {doctorDetails?.qualification?.length ? (
        <>
          <DoctorDetailsDynamicHeader detailsHeader="Qualifications" />
          <Box sx={{ borderBottom: "1px solid #f0f0f0", mb: 3 }}>
            <Carousel>
              {doctorDetails?.qualification?.map(
                (qualification: DoctorQualification, index: number) => (
                  <Box
                    key={index}
                    px={2}
                    sx={{ borderRight: "1px solid #f0f0f0" }}
                  >
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12}>
                        <VerificationTextField
                          name={`${qualification?.value}`}
                          handleChange={handleChange}
                          value={qualification?.value}
                          label="Qualification:"
                          isVerified={
                            qualification?.verification?.status ===
                            Status.VERIFIED
                          }
                          fieldName="qualification"
                          fieldId={qualification._id}
                          handleVerification={handleOpenVerificationDialog}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <VerificationTextField
                          name={"verificationSources"}
                          handleChange={handleChange}
                          value={qualification?.verification?.verificationSources
                            ?.map((source) => source.source)
                            .join(", ")}
                          label="Verification Source:"
                        />
                      </Grid>
                      {qualification?.verification?.verificationSources ? (
                        <Grid item xs={12}>
                          <VerificationTextField
                            name={`verificationSources`}
                            handleChange={handleChange}
                            value={`${qualification?.verification?.verificationSources
                              ?.map((v) =>
                                v.verifiedOn
                                  ? handleGetFullDateWithoutTime(v.verifiedOn)
                                  : "-"
                              )
                              .join(", ")}`}
                            label="Verified On:"
                            sx={{ textTransform: "capitalize" }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                )
              )}
            </Carousel>
          </Box>
        </>
      ) : null}

      {doctorDetails?.specialities?.length ? (
        <>
          <DoctorDetailsDynamicHeader detailsHeader="Specialities" />
          <Box sx={{ borderBottom: "1px solid #f0f0f0", mb: 3 }}>
            <Carousel>
              {doctorDetails?.specialities?.map(
                (speciality: DoctorSpeciality, index: number) => (
                  <Box
                    key={index}
                    px={2}
                    sx={{ borderRight: "1px solid #f0f0f0" }}
                  >
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12}>
                        <VerificationTextField
                          name={`${speciality?.value}`}
                          handleChange={handleChange}
                          value={speciality?.value}
                          label="Speciality:"
                          isVerified={
                            speciality?.verification?.status === Status.VERIFIED
                          }
                          fieldName="speciality"
                          fieldId={speciality._id}
                          handleVerification={handleOpenVerificationDialog}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <VerificationTextField
                          name={"verificationSources"}
                          handleChange={handleChange}
                          value={speciality?.verification?.verificationSources
                            ?.map((source) => source.source)
                            .join(", ")}
                          label="Verification Source:"
                        />
                      </Grid>
                      {speciality?.verification?.verificationSources ? (
                        <Grid item xs={12}>
                          <VerificationTextField
                            name={`verificationSources`}
                            handleChange={handleChange}
                            value={`${speciality?.verification?.verificationSources
                              ?.map((v) =>
                                v.verifiedOn
                                  ? handleGetFullDateWithoutTime(v.verifiedOn)
                                  : "-"
                              )
                              .join(", ")}`}
                            label="Verified On:"
                            sx={{ textTransform: "capitalize" }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                )
              )}
            </Carousel>
          </Box>
        </>
      ) : null}

      {doctorDetails?.contacts?.length ? (
        <>
          <DoctorDetailsDynamicHeader detailsHeader="Contacts" />
          <Box sx={{ borderBottom: "1px solid #f0f0f0", mb: 3 }}>
            <Carousel>
              {doctorDetails?.contacts?.map(
                (contact: Contacts, index: number) => (
                  <Box
                    key={index}
                    px={2}
                    sx={{ borderRight: "1px solid #f0f0f0" }}
                  >
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12}>
                        <VerificationTextField
                          name={`${contact.type}`}
                          handleChange={handleChange}
                          value={contact?.type}
                          label="Contact Type:"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <VerificationTextField
                          name={`${contact?.value}`}
                          handleChange={handleChange}
                          value={contact?.value}
                          label="Contact:"
                          isVerified={
                            contact?.verification?.status === Status.VERIFIED
                          }
                          fieldName="contact"
                          fieldId={contact._id}
                          handleVerification={handleOpenVerificationDialog}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <VerificationTextField
                          name={"Source"}
                          handleChange={handleChange}
                          value={contact?.source ? contact?.source : "-"}
                          label="Source:"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <VerificationTextField
                          name={"verificationSources"}
                          handleChange={handleChange}
                          value={contact?.verification?.verificationSources
                            ?.map((source) => source.source)
                            .join(", ")}
                          label="Verification Source:"
                        />
                      </Grid>
                      {contact?.verification?.verificationSources ? (
                        <Grid item xs={12}>
                          <VerificationTextField
                            name={`verificationSources`}
                            handleChange={handleChange}
                            value={`${contact?.verification?.verificationSources
                              ?.map((v) =>
                                v.verifiedOn
                                  ? handleGetFullDateWithoutTime(v.verifiedOn)
                                  : "-"
                              )
                              .join(", ")}`}
                            label="Verified On:"
                            sx={{ textTransform: "capitalize" }}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                )
              )}
            </Carousel>
          </Box>
        </>
      ) : null}

      {doctorDetails?.addresses?.length ? (
        <>
          <DoctorDetailsDynamicHeader detailsHeader="Addresses" />
          <Box sx={{ borderBottom: "1px solid #f0f0f0", mb: 3 }}>
            <Grid container spacing={2} mb={4}>
              <Carousel>
                {doctorDetails?.addresses?.map((address: Address) => (
                  <Grid item xs={12} mx={2} key={address._id}>
                    <DoctorDetailsHeader
                      subTitle={`${address?.type?.toLocaleUpperCase()} Address (${
                        address.source
                      })`}
                      status={address.verification?.status}
                    />
                    <VerificationTextField
                      name="address"
                      handleChange={handleChange}
                      placeholder=""
                      key={address._id}
                      value={
                        address?.line +
                        ", " +
                        address?.district +
                        ", " +
                        address?.state
                      }
                      multiline={true}
                      isVerified={
                        address?.verification?.status === Status.VERIFIED
                      }
                      fieldName="address"
                      fieldId={address._id}
                      handleVerification={handleOpenVerificationDialog}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Carousel>
            </Grid>
            <ConfirmApprove
              openDialog={showVerificationDialog}
              handleClose={handleCloseVerificationDialog}
              handleApprove={handleVerifyDoctorField}
              name={
                verificationFieldDetails?.fieldName
                  ? verificationFieldDetails?.fieldName
                  : "field"
              }
            />
          </Box>
        </>
      ) : null}
    </>
  );
};

export default DoctorPersonalDetails;
