import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import {
  DoctorUnverifiedImagesModel,
  DoctorUnverifiedImagesResponse,
} from "../../../domain/models/data-entry/fetch-unverified-images";
import { RemoteFetchDoctorsUnverifiedImages } from "../../../data/usecases/data-entry/remote-fetch-unverified-images";

type Filters = {
  page: number;
  limit: number;
  id: string;
  orgCode: string;
  deptCode: string;
};

interface fetchUnverifiedImagesSliceInterface {
  fetchUnverifiedImages: Function;
  unverifiedImages: DoctorUnverifiedImagesModel[];
  totalPages: number;
  totalImages: number;
  fetchUnverifiedImagesState: FetchState;
  reloadUnverifiedImages: Function;
  fetchUnverifiedImagesFilters: Filters;
}

const initialState = {
  unverifiedImages: [],
  totalPages: 0,
  totalImages: 0,
  fetchUnverifiedImagesState: FetchState.DEFAULT,
  fetchUnverifiedImagesFilters: {} as Filters,
};

export const useFetchUnverifiedImagesSlice: StateCreator<
  fetchUnverifiedImagesSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    fetchUnverifiedImages: async (
      page: number,
      limit: number,
      id: string,
      orgCode: string,
      deptCode: string
    ) => {
      const storage = LocalJsonStorage.getInstance();
      const token = storage.get(AUTH_TOKEN);
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
      });

      const fetchUnverifiedImages = new RemoteFetchDoctorsUnverifiedImages(
        `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_UNVERIFIED_IMAGES}`,
        axiosHttpClient
      );

      const params = { page, limit, id, orgCode, deptCode };

      try {
        set({ fetchUnverifiedImagesState: FetchState.LOADING });

        let result: DoctorUnverifiedImagesResponse =
          await fetchUnverifiedImages.fetch(params);
        if (result.success) {
          set({
            unverifiedImages: result.data.result,
            totalPages: result.data.totalPages,
            totalImages: result.data.total,
            fetchUnverifiedImagesFilters: params,
          });

          set({ fetchUnverifiedImagesState: FetchState.SUCCESS });
        }
      } catch (err) {
        set({ fetchUnverifiedImagesState: FetchState.ERROR });
      }
    },
    reloadUnverifiedImages: () => {
      const { page, limit, id, orgCode, deptCode } =
        get().fetchUnverifiedImagesFilters;
      get().fetchUnverifiedImages(page, limit, id, orgCode, deptCode);
    },
  };
};
