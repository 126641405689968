import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemoteGetImageRejectionReasons } from "../../../data/usecases/data-entry/doctor-verification/remote-get-image-rejection-reasons";
import {
  ImageRejectionReasons,
  ImageRejectionReasonsResponse,
} from "../../../domain/models/image-rejection-reasons";

interface fetchImageRejectionReasonsSliceInterface {
  fetchImageRejectionReasons: Function;
  imageRejectionReasons: ImageRejectionReasons;
  fetchImageRejectionReasonsState: FetchState;
}

const initialState = {
  imageRejectionReasons: {} as ImageRejectionReasons,
  fetchImageRejectionReasonsState: FetchState.DEFAULT,
};

export const useFetchImageRejectionReasonsSlice: StateCreator<
  fetchImageRejectionReasonsSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    fetchImageRejectionReasons: async () => {
      const storage = LocalJsonStorage.getInstance();
      const token = storage.get(AUTH_TOKEN);
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
      });

      const fetchImageRejectionReasons = new RemoteGetImageRejectionReasons(
        `${DOCTOR_SERVICE_API_URL}${Endpoints.GET_IMAGE_REJECTION_REASONS}`,
        axiosHttpClient
      );

      try {
        set({ fetchImageRejectionReasonsState: FetchState.LOADING });

        let result: ImageRejectionReasonsResponse =
          await fetchImageRejectionReasons.get({});
        if (result.success) {
          set({ imageRejectionReasons: result.data });

          set({ fetchImageRejectionReasonsState: FetchState.SUCCESS });
        }
      } catch (err) {
        set({ fetchImageRejectionReasonsState: FetchState.ERROR });
      }
    },
  };
};
