import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Tooltip,
  Box,
  Snackbar,
  Alert,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import Swal from "sweetalert2";
import ImageDraggableDialogBox from "../../admin/common/image-draggable-dialog-box";
import Status from "../../../../domain/enums/status";
import { ImageType } from "../../../../domain/enums/image-types";
import AutoCompleteComponent from "../doctorProfilerVerifcation/AutoCompleteComponent";
import AddImageCommentModal from "../doctorProfilerVerifcation/AddImageCommentModal";
import ImageDisplay from "../../../ga-components/image";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import RejectImageModal from "./RejectImageModal";
import { getUniqueDoctorImages } from "../../../../utils/Helpers";
import { useDoctorProfilerVerification } from "../../../../store/main/DoctorProfilerVerificationStore";
import { DoctorUnverifiedImagesModel } from "../../../../domain/models/data-entry/fetch-unverified-images";
import FetchState from "../../../../domain/enums/fetch-state-type";

type myProps = {
  doctors: DoctorUnverifiedImagesModel[];
  handleSubmitCallBack: Function;
};

export type alertProps = {
  open: boolean;
  text?: string;
  severity?: "error" | "info" | "success" | "warning";
};

export type ImageSelectionProps = {
  imageId: string;
  doctorId: string;
  selectedImageType: any;
};

const ImageListingCard: React.FC<myProps> = ({
  doctors,
  handleSubmitCallBack,
}) => {
  const {
    fetchImageTypes,
    imageTypes,
    updateImageTypes,
    verifyBulkImages,
    verifyBulkImagesState,
  } = useDoctorProfilerVerification();

  const [selectedImageType, setSelectedImageType] =
    useState<ImageSelectionProps>({
      imageId: "",
      doctorId: "",
      selectedImageType: "",
    });
  const [selectedImageTypeArray, setSelectedImageTypeArray] = useState<
    ImageSelectionProps[]
  >([]);

  const [openImage, setOpenImage] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [toast, setToast] = useState<alertProps>({
    open: false,
    text: "",
    severity: "success",
  });

  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});
  const [comment, setComment] = useState<string>("");
  const [openImageRejectionModal, setOpenImageVerificationModal] =
    useState<boolean>(false);
  const [openImageCommentModal, setOpenImageCommentModal] =
    useState<boolean>(false);
  const [rotateImage, setRotateImage] = useState(0);

  const handleUpdateImageType = async () => {
    if (
      selectedOptions &&
      selectedImageType.selectedImageType !== Status.REJECTED &&
      selectedImageType.selectedImageType !== ImageType.OTHERS
    )
      await updateImageTypes(
        selectedImageType.doctorId,
        selectedImageType.imageId,
        selectedImageType.selectedImageType
      );
  };

  const handleSubmitComment = async () => {
    await updateImageTypes(
      selectedImageType.doctorId,
      selectedImageType.imageId,
      selectedImageType.selectedImageType,
      comment
    );
    setOpenImageCommentModal(false);
    setComment("");
  };

  const handleOpenImageRejectionModal = (cardId: string, imageId: string) => {
    setOpenImageVerificationModal(true);
    setSelectedImageType({
      doctorId: cardId,
      imageId: imageId,
      selectedImageType: Status.REJECTED,
    });
  };

  const handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setToast({ open: false });
  };

  const handleSubmit = async () => {
    const filteredArray = selectedImageTypeArray.filter(
      (obj: ImageSelectionProps) =>
        !(selectedOptions[obj.imageId] === Status.REJECTED)
    );
    const images: { doctorId: string; imageId: string }[] = filteredArray.map(
      ({ doctorId, imageId }) => ({
        doctorId,
        imageId,
      })
    );

    await verifyBulkImages({ images: images });
    if (verifyBulkImagesState === FetchState.SUCCESS) {
      handleSubmitCallBack();
    }
  };

  const handleTypeSelect = (id: string, imageId: string, type: string) => {
    setSelectedImageType({
      doctorId: id,
      imageId: imageId,
      selectedImageType: type,
    });
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [imageId]: type,
    }));
  };

  const handleCloseImageRejectionModal = () => {
    setOpenImageVerificationModal(false);
  };

  const handleCloseImageRejectionCallback = (
    doctorId: string,
    imageId: string
  ) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [imageId]: Status.REJECTED,
    }));
  };

  const handleCommentChange = (value: string) => {
    setComment(value);
  };
  const handleAddComment = () => {
    setOpenImageCommentModal(true);
  };

  useEffect(() => {
    fetchImageTypes();
  }, []);

  useEffect(() => {
    selectedImageTypeArray.forEach((doctor, index) => {
      if (
        doctor.doctorId === "" &&
        doctor.imageId === "" &&
        doctor.selectedImageType === ""
      ) {
        selectedImageTypeArray.splice(index, 1);
      }
    });
    if (
      selectedImageType.doctorId &&
      selectedImageType.imageId &&
      selectedImageType.selectedImageType
    ) {
      handleUpdateImageType();
    }
    setSelectedImageTypeArray([...selectedImageTypeArray, selectedImageType]);
  }, [selectedOptions]);

  useEffect(() => {
    if (selectedImageType.selectedImageType === ImageType.OTHERS) {
      handleAddComment();
    }
  }, [selectedImageType]);

  return (
    <Container maxWidth="xl">
      {openImage && (
        <ImageDraggableDialogBox
          setOpenImage={setOpenImage}
          openImage={openImage}
          imageUrl={imageUrl}
        />
      )}
      <Grid container spacing={2}>
        {doctors?.map((doctor: DoctorUnverifiedImagesModel, index: number) => (
          <Grid item xs={4} mb={2} key={index}>
            <Card
              sx={{
                border: `1px solid  ${
                  selectedOptions[doctor?.images?._id] === Status.REJECTED
                    ? "rgba(255, 86, 86, 0.8)"
                    : "#e0e0e0"
                }`,
                borderRadius: "12px",
                zIndex: "0 !important",
                px: 2,
                backdropFilter: "blur(8px)",
              }}
              key={doctor._id}
            >
              <ImageDisplay
                setRotateImage={setRotateImage}
                rotateImage={rotateImage}
                imageLink={doctor?.images.url}
              />

              <CardContent>
                <Grid container spacing={1} mb={2} alignItems={"center"}>
                  <Grid item xs={10}>
                    <AutoCompleteComponent
                      currentSelectedValue=""
                      handleTypeSelect={handleTypeSelect}
                      imageTypeOptions={imageTypes}
                      id={doctor?._id}
                      imageId={doctor?.images?._id}
                      disabled={
                        selectedOptions[doctor?.images?._id] === Status.REJECTED
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Reject Image">
                      <IconButton
                        sx={{ mt: 3 }}
                        onClick={() =>
                          handleOpenImageRejectionModal(
                            doctor?._id,
                            doctor?.images?._id
                          )
                        }
                      >
                        <ImageNotSupportedIcon
                          sx={{ fontSize: "30px", color: "red" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Box
                  p={2}
                  sx={{ border: "1px solid #f0f0f0", borderRadius: "12px" }}
                  mb={2}
                >
                  doctor Name:{" "}
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      lineHeight: "24px",
                    }}
                  >
                    <span style={{ color: "orange", overflowX: "visible" }}>
                      {doctor?.fullNames
                        ?.map((fullName) => fullName?.name)
                        ?.join(",")}{" "}
                      <br />
                    </span>
                  </Typography>
                </Box>

                <Box
                  p={2}
                  sx={{ border: "1px solid #f0f0f0", borderRadius: "12px" }}
                  mb={2}
                >
                  doctor Contacts:{" "}
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      lineHeight: "24px",
                    }}
                  >
                    <span style={{ color: "orange" }}>
                      {doctor?.contacts
                        ?.map((contacts) => contacts?.value)
                        ?.join(", ")
                        ?.concat("")}
                    </span>
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {doctors?.length && (
        <Container maxWidth="sm">
          <Button
            variant="contained"
            fullWidth
            sx={{
              color: "#fff",
              fontWeight: 600,
            }}
            onClick={handleSubmit}
            disabled={
              getUniqueDoctorImages(selectedImageTypeArray).length <
              doctors.length
            }
          >
            Mark as Verified
          </Button>
        </Container>
      )}
      <RejectImageModal
        handleClose={handleCloseImageRejectionModal}
        open={openImageRejectionModal}
        doctorId={selectedImageType.doctorId}
        imageId={selectedImageType.imageId}
        handleCallBack={handleCloseImageRejectionCallback}
      />
      <AddImageCommentModal
        open={openImageCommentModal}
        handleClose={() => setOpenImageCommentModal(false)}
        value={comment}
        handleConfirm={() => handleSubmitComment()}
        onInputChange={handleCommentChange}
      />
      <Snackbar
        open={toast.open}
        onClose={handleToastClose}
        autoHideDuration={3000}
      >
        <Alert
          severity={toast.severity}
          onClose={handleToastClose}
          sx={{ width: "100%" }}
        >
          {toast.text}
        </Alert>
      </Snackbar>
    </Container>
  );
};
export default ImageListingCard;
