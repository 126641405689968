import * as React from "react";
import {
  Box,
  InputAdornment,
  InputLabel,
  SxProps,
  TextField,
  Theme,
  Tooltip,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

interface Props {
  id?: string;
  label?: string;
  placeholder?: string;
  value: string | number | null | undefined;
  name: string;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  type?: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  handleChange: any;
  fullWidth?: boolean;
  extraHelperText?: string;
  handleExtraHelperClick?: any;
  isVerified?: boolean;
  fieldName?: string;
  fieldId?: string;
  handleVerification?: Function;
}

const VerificationTextField: React.FC<Props> = ({
  label,
  id,
  placeholder,
  value,
  name,
  multiline,
  rows,
  type,
  required,
  sx,
  disabled,
  handleChange,
  fullWidth,
  isVerified,
  fieldName,
  fieldId,
  handleVerification,
}) => {
  return (
    <Box mt={1} sx={{ flexGrow: 1 }}>
      <InputLabel
        sx={{
          fontWeight: 500,
          fontSize: "1rem",
        }}
        shrink
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <TextField
        InputProps={{
          style: {
            height: multiline ? "auto" : "54px",
            borderRadius: "0.3125rem",
            fontSize: "0.9375rem",
            boxShadow: "none",
          },

          endAdornment: (
            <InputAdornment position="start">
              {isVerified ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                fieldName && (
                  <Tooltip title="Pending, click to verify">
                    <ReportGmailerrorredIcon
                      color="warning"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        handleVerification &&
                          handleVerification(fieldId, fieldName);
                      }}
                    />
                  </Tooltip>
                )
              )}
            </InputAdornment>
          ),
        }}
        fullWidth
        required={required}
        placeholder={placeholder}
        value={value ? value : "-"}
        onChange={handleChange}
        id={id}
        rows={rows}
        multiline={multiline}
        name={name}
        sx={{
          "& ::placeholder": {
            color: "#242424",
            fontSize: "0.9375rem",
            fontWeight: "400",
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#242424",
          },
          ...sx,
        }}
        type={type ? type : "text"}
        disabled
      />
    </Box>
  );
};
export default VerificationTextField;
