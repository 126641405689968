import { DoctorUnverifiedImagesResponse } from "../../../domain/models/data-entry/fetch-unverified-images";
import {
  DoctorUnverifiedImagesParams,
  FetchDoctorsUnverifiedImages,
} from "../../../domain/usages/data-entry/fetch-unverified-images";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchDoctorsUnverifiedImages
  implements FetchDoctorsUnverifiedImages
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    params: DoctorUnverifiedImagesParams.Params
  ): Promise<DoctorUnverifiedImagesResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.data;
  }
}
