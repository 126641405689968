export const generateOptions = () => {
  const options = [];
  for (let i = 3; i <= 30; i += 3) {
    options.push(i);
  }
  return options;
};

export const getUniqueDoctorImages = (
  doctors: {
    doctorId: string;
    imageId: string;
    selectedImageType: string;
  }[]
) => {
  const uniqueDoctors = [];
  const visitedDoctors = new Set();

  for (const doctor of doctors) {
    const key = doctor.doctorId + "-" + doctor.imageId;

    if (!visitedDoctors.has(key)) {
      uniqueDoctors.push(doctor);
      visitedDoctors.add(key);
    }
  }

  return uniqueDoctors;
};
