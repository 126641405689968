import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  MASTER_SERVICE_API_URL,
  MASTER_SERVICE_TOKEN,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemoteFetchDepartments } from "../../../data/usecases/remote-fetch-departments";
import {
  Department,
  DepartmentResponse,
} from "../../../domain/models/departments-response";

interface fetchDepartmentsSliceInterface {
  fetchDepartments: Function;
  departments: Department[];
  fetchDepartmentsState: FetchState;
}

const initialState = {
  departments: [],
  fetchDepartmentsState: FetchState.DEFAULT,
};

export const useFetchDepartmentsSlice: StateCreator<
  fetchDepartmentsSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    fetchDepartments: async () => {
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${MASTER_SERVICE_TOKEN}`,
      });

      const fetchDepartments = new RemoteFetchDepartments(
        `${MASTER_SERVICE_API_URL}${Endpoints.FETCH_DEPARTMENTS}`,
        axiosHttpClient
      );

      try {
        set({ fetchDepartmentsState: FetchState.LOADING });

        let result: DepartmentResponse = await fetchDepartments.fetch();
        if (result.success) {
          set({ departments: result.data });

          set({ fetchDepartmentsState: FetchState.SUCCESS });
        }
      } catch (err) {
        set({ fetchDepartmentsState: FetchState.ERROR });
      }
    },
  };
};
