import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Swal from "sweetalert2";
import { VerifyImagesBulkRequest } from "../../../domain/models/data-entry/verify-bulk-images";
import { RemoteVerifyBulkImages } from "../../../data/usecases/data-entry/remote-verify-bulk-images";
import { useDoctorProfilerVerification } from "../../main/DoctorProfilerVerificationStore";

interface verifyBulkImagesSliceInterface {
  verifyBulkImages: Function;
  verifyBulkImagesState: FetchState;
}

const initialState = {
  verifyBulkImagesState: FetchState.DEFAULT,
};

export const useVerifyBulkImagesSlice: StateCreator<
  verifyBulkImagesSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    verifyBulkImages: async (images: VerifyImagesBulkRequest) => {
      const storage = LocalJsonStorage.getInstance();
      const token = storage.get(AUTH_TOKEN);
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
      });

      const verifyBulkImages = new RemoteVerifyBulkImages(
        `${DOCTOR_SERVICE_API_URL}${Endpoints.VERIFY_IMAGES_BULK}`,
        axiosHttpClient
      );

      try {
        set({ verifyBulkImagesState: FetchState.LOADING });

        let result = await verifyBulkImages.verify(images);
        if (result.success) {
          set({ verifyBulkImagesState: FetchState.SUCCESS });
          await useDoctorProfilerVerification
            .getState()
            .reloadUnverifiedImages();
          Swal.fire({
            title: "Images Verified",
            icon: "success",
            timer: 800,
            showConfirmButton: false,
          });
        } else {
          set({ verifyBulkImagesState: FetchState.ERROR });
          Swal.fire({
            icon: "error",
            title: "Images Rejected",
            text: result?.errors && result?.errors[0]?.message,
          });
        }
      } catch (err) {
        set({ verifyBulkImagesState: FetchState.ERROR });
        Swal.fire("Internal server error", "", "error");
      }
    },
  };
};
