import { StateCreator } from "zustand";
import { DoctorData } from "../../../domain/models/doctor/doctorData";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemoteGetDoctorByID } from "../../../data/usecases/data-entry/doctor-verification/remote-get-doctor-data-by-id";

interface FetchDoctorSliceInterface {
  fetchDoctorById: Function;
  selectedDoctor: DoctorData;
  fetchDoctorByIdState: FetchState;
}

const initialState = {
  selectedDoctor: {} as DoctorData,
  fetchDoctorByIdState: FetchState.DEFAULT,
};

export const useFetchDoctorByIdSlice: StateCreator<
  FetchDoctorSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    fetchDoctorById: async (id: string) => {
      const storage = LocalJsonStorage.getInstance();
      const token = storage.get(AUTH_TOKEN);
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
      });

      const getDoctorById = new RemoteGetDoctorByID(
        `${DOCTOR_SERVICE_API_URL}${Endpoints.GET_DOCTOR_DATA_BY_ID}`,
        axiosHttpClient
      );
      try {
        set({ fetchDoctorByIdState: FetchState.LOADING });

        let result = await getDoctorById.get(id, {});
        if (result.success) {
          console.log(result.data);
          set({ selectedDoctor: result.data });

          set({ fetchDoctorByIdState: FetchState.SUCCESS });
        }
      } catch (err) {
        set({ fetchDoctorByIdState: FetchState.ERROR });
      }
    },
  };
};
