import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemoteVerifyDoctorQualification } from "../../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-qualification";
import { RemoteVerifyDoctorSpeciality } from "../../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-speciality";
import { useDoctorStore } from "../../main/DoctorStore";
import { RemoteVerifyDoctorContacts } from "../../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-contacts";
import { RemoteVerifyDoctorName } from "../../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-name";
import { RemoteVerifyDoctorAddress } from "../../../data/usecases/data-entry/doctor-verification/remote-verify-doctor-address";
import Swal from "sweetalert2";

interface FetchDoctorSliceInterface {
  verifyDoctorField: Function;
  verifyDoctorFieldState: FetchState;
}

const initialState = {
  verifyDoctorFieldState: FetchState.DEFAULT,
};

const fieldMapping: Record<
  string,
  {
    endpoint: string;
    RemoteVerifyClass: new (url: string, httpClient: AxiosHttpClient) => any;
  }
> = {
  qualification: {
    endpoint: Endpoints.VERIFY_QUALIFICATION,
    RemoteVerifyClass: RemoteVerifyDoctorQualification,
  },
  speciality: {
    endpoint: Endpoints.VERIFY_SPECIALITY,
    RemoteVerifyClass: RemoteVerifyDoctorSpeciality,
  },
  contact: {
    endpoint: Endpoints.VERIFY_DOCTOR_CONTACT,
    RemoteVerifyClass: RemoteVerifyDoctorContacts,
  },
  name: {
    endpoint: Endpoints.VERIFY_NAME,
    RemoteVerifyClass: RemoteVerifyDoctorName,
  },
  address: {
    endpoint: Endpoints.VERIFY_ADDRESS,
    RemoteVerifyClass: RemoteVerifyDoctorAddress,
  },
};

export const useVerifyDoctorFieldSlice: StateCreator<
  FetchDoctorSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    verifyDoctorField: async (
      id: string,
      fieldId: string,
      fieldName: string
    ) => {
      const storage = LocalJsonStorage.getInstance();
      const token = storage.get(AUTH_TOKEN);
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
      });

      const fieldConfig = fieldMapping[fieldName];
      if (!fieldConfig) {
        console.error(`Field name "${fieldName}" is not configured.`);
        return;
      }

      const { endpoint, RemoteVerifyClass } = fieldConfig;

      const verifyFieldInstance = new RemoteVerifyClass(
        `${DOCTOR_SERVICE_API_URL}${endpoint}`,
        axiosHttpClient
      );

      set({ verifyDoctorFieldState: FetchState.LOADING });

      const result = await verifyFieldInstance.verify(id, fieldId);
      try {
        if (result.success) {
          useDoctorStore.getState().fetchDoctorById(id);
          set({ verifyDoctorFieldState: FetchState.SUCCESS });
          Swal.fire(result.data.message, "", "success");
        }
      } catch (err) {
        set({ verifyDoctorFieldState: FetchState.ERROR });
        Swal.fire(result.message, "", "error");
      }
    },
  };
};
