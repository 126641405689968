import React from "react";
import { Typography, Box, Button } from "@mui/material";
import NotFoundArtwork from "../../../ga-components/NotFound";

type NotFoundProps = {
  handleClick: Function;
};

export const NotFoundComponent: React.FC<NotFoundProps> = ({ handleClick }) => {
  return (
    <Box textAlign={"center"}>
      <Box>
        <Typography fontSize={"1.875rem"} fontWeight={700} mb={2}>
          No Customer found!
        </Typography>
        <Button
          onClick={() => handleClick()}
          variant="contained"
          sx={{ mb: 2, color: "#fff" }}
        >
          BACK TO LISTING
        </Button>
      </Box>
      <NotFoundArtwork size={600} />
    </Box>
  );
};
