import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Swal from "sweetalert2";
import { RemoteRejectImage } from "../../../data/usecases/data-entry/doctor-verification/remote-reject-image";
import { RejectImage } from "../../../domain/usages/data-entry/doctor-verification/reject-image";

interface rejectImageSliceInterface {
  rejectImage: Function;
  rejectImageState: FetchState;
}

const initialState = {
  rejectImageState: FetchState.DEFAULT,
};

export const useRejectImageSlice: StateCreator<rejectImageSliceInterface> = (
  set,
  get
) => {
  return {
    ...initialState,
    rejectImage: async (
      doctorId: string,
      imageId: string,
      reasonCode: string,
      rejectionReason?: string
    ) => {
      const storage = LocalJsonStorage.getInstance();
      const token = storage.get(AUTH_TOKEN);
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
      });

      const rejectImage = new RemoteRejectImage(
        `${DOCTOR_SERVICE_API_URL}${Endpoints.MARK_IMAGE_AS_REJECT}`,
        axiosHttpClient
      );

      let payload: RejectImage.Params = {
        reasonCode,
      };

      if (rejectionReason) {
        payload.rejectionReason = rejectionReason;
      }

      try {
        set({ rejectImageState: FetchState.LOADING });

        let result = await rejectImage.reject(doctorId, imageId, payload);
        if (result.success) {
          set({ rejectImageState: FetchState.SUCCESS });
          Swal.fire({
            title: "Image Rejected",
            text: result.data.message,
            icon: "success",
            timer: 800,
          });
        } else {
          set({ rejectImageState: FetchState.ERROR });
          Swal.fire({
            icon: "error",
            title: "Image Rejection falied",
            text: result?.errors && result?.errors[0].message,
          });
        }
      } catch (err) {
        set({ rejectImageState: FetchState.ERROR });
        Swal.fire("Internal server error", "", "error");
      }
    },
  };
};
