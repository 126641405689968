import React from "react";
import { AuthenticateFactory } from "./main/AuthenticateFactory";
import { CreateDoctorFactory } from "./main/CreateDoctorFactory";
import { CreateHospitalFactory } from "./main/CreateHospitalFactory";
import { DashboardPageFactory } from "./main/DashboardPageFactory";
import { DoctorPageFactory } from "./main/DoctorPageFactory";
import { DoctorProfilePageFactory } from "./main/DoctorProfilePageFactory";
import { HomeFactory } from "./main/HomeFactory";
import { HospitalPageFactory } from "./main/HospitalPageFactory";
import { HospitalProfilePageFactory } from "./main/HospitalProfilePageFactory";
import { DataEntryAdminDashboardPageFactory } from "./main/DataEntryAdminDashboardPageFactory";
import { LoginPageFactory } from "./main/LoginPageFactory";
import LogoutPage from "./presentation/pages/logout-page";
import MyProfilePage from "./presentation/pages/my-profile-page";
import DoctorAuthenticatePage from "./presentation/pages/data-entry/DoctorAuthenticatePage";
import { DoctorInformationFactory } from "./main/DoctorInformationFactory";
import { DoctorVerificationFactory } from "./main/data-entry/DoctorVerificationFactory";
import { LoginAuthenticateFactory } from "./main/data-entry/LoginAuthenticateFactory";
import UnderMaintenancePage from "./presentation/pages/under-maintenance-page";
import HomePage from "./presentation/pages/data-entry/HomePage";
import { DoctorVerificationCountSummaryFactory } from "./main/data-entry/DoctorVerificationCountSummaryFactory";
import { DoctorDetailsPage } from "../src/presentation/pages/doctor-details-page";
import ImageVerificationPage from "../src/presentation/pages/image-verification-page";

export const auths = {
  authenticate: "/auth/:authToken",
  login: "auth/login",
  logout: "/auth/logout",
};

export const pageRoutes = {
  home: "/",
  doctorProfile: "doctors/:id",
  createHospital: "hospitals/create",
  createDoctor: "doctors/create",
  hospitalProfile: "hospitals/:id",

  myProfile: "my-profile",
  dashboard: "dashboard",
  doctors: "doctors",
  hospitals: "hospitals",

  dataEntry: "data-entry",
  doctorsInfo: "doctorsinfo",
  underMaintenance: "underMaintenance",
  dataEntryHomePage: "/admin",
  doctorVerification: "/admin/doctor-verification",
  doctorImageVerification: "/admin/image-verification",
  // doctorAuthenticatePage: "admin",
  dataEntryVerficationLogin: "/data-entry/verification/login",
  doctorsCountSummary: "/admin/countsSummary",
  doctorDetails: "/admin/doctor-details",
};

export interface AppRoute {
  path: string;
  name: string;
  component: React.FC;
  children?: AppRoute[];
}

export let authRoutes: AppRoute[] = [
  {
    path: auths.authenticate,
    name: "Authenticate Page",
    component: AuthenticateFactory,
  } as AppRoute,
  {
    path: auths.login,
    name: "Login Page",
    component: LoginPageFactory,
  } as AppRoute,
  {
    path: auths.logout,
    name: "Logout Page",
    component: LogoutPage,
  } as AppRoute,
];

let indexRoutes: AppRoute[] = [
  {
    path: pageRoutes.dataEntry,
    name: "Data Entry Page",
    component: DataEntryAdminDashboardPageFactory,
  },
  {
    path: pageRoutes.home,
    name: "Home Page",
    component: HomeFactory,
    children: [
      {
        path: pageRoutes.dashboard,
        name: "Dashboard Page",
        component: DashboardPageFactory,
      } as AppRoute,
      {
        path: pageRoutes.underMaintenance,
        name: "Dashboard Page",
        component: UnderMaintenancePage,
      } as AppRoute,
      {
        path: pageRoutes.doctors,
        name: "Doctor Page",
        component: DoctorPageFactory,
      } as AppRoute,
      {
        path: pageRoutes.hospitals,
        name: "Hospital Page",
        component: HospitalPageFactory,
      } as AppRoute,
      {
        path: pageRoutes.myProfile,
        name: "My Profile Page",
        component: MyProfilePage,
      } as AppRoute,
    ],
  } as AppRoute,
  {
    path: pageRoutes.doctorProfile,
    name: "Doctor Profile Page",
    component: DoctorProfilePageFactory,
  } as AppRoute,
  {
    path: pageRoutes.hospitalProfile,
    name: "Hospital Profile Page",
    component: HospitalProfilePageFactory,
  } as AppRoute,
  {
    path: pageRoutes.createHospital,
    name: "Create Hospital Page",
    component: CreateHospitalFactory,
  } as AppRoute,
  {
    path: pageRoutes.createDoctor,
    name: "Create Doctor Page",
    component: CreateDoctorFactory,
  } as AppRoute,
  {
    path: pageRoutes.doctorsInfo,
    name: "Dr Information Page",
    component: DoctorInformationFactory,
  } as AppRoute,
  {
    path: pageRoutes.doctorsInfo,
    name: "Dr Information Page",
    component: DoctorInformationFactory,
  } as AppRoute,
  // {
  //   path: pageRoutes.doctorAuthenticatePage,
  //   name: "Authenticate Page",
  //   component: DoctorAuthenticatePage,
  // } as AppRoute,

  {
    path: pageRoutes.dataEntryVerficationLogin,
    name: "Data Entry Login Page",
    component: LoginAuthenticateFactory,
  } as AppRoute,
  {
    path: pageRoutes.dataEntryHomePage,
    name: "Home",
    component: HomePage,
    children: [
      {
        path: pageRoutes.doctorVerification,
        name: "Doctor Verification Page",
        component: DoctorVerificationFactory,
      } as AppRoute,
      {
        path: pageRoutes.doctorImageVerification,
        name: "Doctor Image Verification Page",
        component: ImageVerificationPage,
      } as AppRoute,
      {
        path: pageRoutes.doctorsCountSummary,
        name: "Doctor Verification Counts Page",
        component: DoctorVerificationCountSummaryFactory,
      } as AppRoute,
      {
        path: pageRoutes.doctorDetails,
        name: "Doctor Details Page",
        component: DoctorDetailsPage,
      } as AppRoute,
    ],
  } as AppRoute,
];

export default indexRoutes;
