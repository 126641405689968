import React from "react";
import { Stack } from "@mui/material";

interface Props {
  children: React.ReactNode;
  gap?: string | number;
  sx?: any;
  onClick?: () => void;
}
const CenteredStack: React.FC<Props> = ({ children, gap, sx, onClick }) => {
  return (
    <div>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={2}
        gap={gap}
        sx={{ ...sx }}
        onClick={onClick}
      >
        {children}
      </Stack>
    </div>
  );
};

export default CenteredStack;
