import { Box, Grid, Skeleton, Stack } from "@mui/material";

export const DoctorDetailsSkeleton = () => {
  const loadingOccurence = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <Box>
      <Stack direction={"row"} justifyContent={"space-evenly"}>
        <Skeleton height={100} width={200} />
        <Skeleton height={100} width={200} />
        <Skeleton height={100} width={200} />
        <Skeleton height={100} width={200} />
      </Stack>
      <Grid container spacing={4}>
        {loadingOccurence?.map((item) => (
          <Grid key={item} item xs={12} sm={12} md={6} lg={6}>
            <Skeleton height={100} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
