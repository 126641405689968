import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  InputBase,
  IconButton,
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

type Props = {
  open: boolean;
  handleClose: Function;
  handleConfirm: Function;
  onInputChange: Function;
  value: string;
};
const AddImageCommentModal: React.FC<Props> = ({
  open,
  handleClose,
  value,
  handleConfirm,
  onInputChange,
}) => {
  const handleCommentChange = (event: any) => {
    const inputValue = event.target.value;
    onInputChange(inputValue);
  };
  return (
    <>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogContent>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
              border: "1px solid #f0f0f0",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Add comments"
              value={value}
              onChange={handleCommentChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="comment">
              <CommentIcon />
            </IconButton>
          </Paper>
        </DialogContent>
        <DialogActions>
          <>
            <div style={{ paddingBottom: "15px", paddingRight: "23px" }}>
              <Button
                variant="contained"
                onClick={(event) => handleConfirm(event)}
              >
                Update
              </Button>
            </div>
          </>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddImageCommentModal;
