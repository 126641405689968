import { Typography } from "@mui/material";
import React from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import CenteredStack from "../../../ga-components/centered-stack";
import { ActivStatus } from "../../../../domain/enums/active-status";

interface Props {
  title?: string;
  subTitle?: string;
  status?: string;
}
const DoctorDetailsHeader: React.FC<Props> = ({ title, subTitle, status }) => {
  return (
    <>
      <CenteredStack sx={{ mb: "0 !important" }}>
        <>
          {title && (
            <Typography ml={"2rem"} fontWeight={600} fontSize={"1.25rem"}>
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography fontWeight={600} fontSize={"1rem"}>
              {subTitle}
            </Typography>
          )}
        </>
        {status === ActivStatus.ACTIVE && (
          <CenteredStack sx={{ mt: 1 }}>
            <TaskAltIcon color="success" />
            <Typography
              textTransform={"uppercase"}
              color="green"
              fontWeight={600}
              fontSize={"0.875rem"}
            >
              Active
            </Typography>
          </CenteredStack>
        )}
        {status === ActivStatus.IN_ACTIVE ||
          (status === ActivStatus.IN_ACTIVE && (
            <CenteredStack sx={{ mt: 1, color: "red" }}>
              <CancelIcon color="error" />
              <Typography
                textTransform={"uppercase"}
                fontWeight={600}
                fontSize={"0.875rem"}
              >
                Inactive
              </Typography>
            </CenteredStack>
          ))}
      </CenteredStack>
    </>
  );
};

export default DoctorDetailsHeader;
