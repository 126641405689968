import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import NotAvailable from "./common/not-available";
import { Mapping } from "../../../domain/models/admin-doctor";
import DoctorDetailsHeader from "./common/doctor-details-header";
import DoctorDetailsDynamicHeader from "./common/doctor-details-dynamic-header";
import VerificationTextField from "../common/textfield/VerificationTextField";

interface Props {
  mappings: Mapping[];
}

const DoctorMappingDetails: React.FC<Props> = ({ mappings }) => {
  const handleChange = () => {};

  return (
    <Box>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <DoctorDetailsDynamicHeader detailsHeader="Mapping Details" />
      </Stack>
      {mappings?.map((mapping: Mapping, index: number) => (
        <Box key={index} sx={{ borderBottom: "1px solid #f0f0f0", mb: 3 }}>
          <DoctorDetailsHeader
            subTitle={`#${index + 1}`}
            status={mapping?.status}
          />

          <Grid container spacing={2} mb={4}>
            <Grid item xs={3}>
              <VerificationTextField
                name={`${mapping.orgCode}`}
                handleChange={handleChange}
                placeholder="Enter Oranization code"
                value={mapping.orgCode}
                label="Oranization code:"
              />
            </Grid>
            <Grid item xs={3}>
              <VerificationTextField
                name={`${mapping.deptCode}`}
                handleChange={handleChange}
                placeholder="Enter Dept Code"
                value={mapping.deptCode}
                label="Dept Code:"
              />
            </Grid>
            <Grid item xs={3}>
              <VerificationTextField
                name={`${mapping.hqCode}`}
                handleChange={handleChange}
                placeholder="Enter HQ code"
                value={mapping.hqCode}
                label="HQ Code:"
              />
            </Grid>
            <Grid item xs={3}>
              <VerificationTextField
                name={`${mapping.hqName}`}
                handleChange={handleChange}
                placeholder="Enter HQ name"
                value={mapping.hqName}
                label="HQ Name:"
              />
            </Grid>
            <Grid item xs={3}>
              <VerificationTextField
                name={`${mapping.brickCode}`}
                handleChange={handleChange}
                placeholder=""
                value={mapping.brickCode}
                label="Brick Code:"
              />
            </Grid>
            <Grid item xs={3}>
              <VerificationTextField
                name={`${mapping.brickName}`}
                handleChange={handleChange}
                placeholder=""
                value={mapping.brickName}
                label="Brick Name:"
              />
            </Grid>
            <Grid item xs={3}>
              <VerificationTextField
                name={`${mapping.appCode}`}
                handleChange={handleChange}
                placeholder=""
                value={mapping.appCode}
                label="App Code:"
              />
            </Grid>
            <Grid item xs={3}>
              <VerificationTextField
                name={`${mapping.teamCode}`}
                handleChange={handleChange}
                placeholder=""
                value={mapping?.teamCode}
                label="Team Code:"
              />
            </Grid>
          </Grid>
        </Box>
      ))}
      {mappings?.length === 0 && <NotAvailable title="Mapping" />}
    </Box>
  );
};

export default DoctorMappingDetails;
