import { ImageTypeResponse } from "../../../../domain/models/data-entry/imageTypes";
import {
  FetchImageType,
  ImageTypeResponseParams,
} from "../../../../domain/usages/data-entry/doctor-verification/fetch-image-types";
import { HttpConstants } from "../../../protocols/http/http-constants";
import { HttpGetClient } from "../../../protocols/http/http-get-client";

export class RemoteFetchImageType implements FetchImageType {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}
  async fetch(
    params: ImageTypeResponseParams.Params
  ): Promise<ImageTypeResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.data;
  }
}
