import { create } from "zustand";
import { useFetchDepartmentsSlice } from "../slice/common/fetchDepartmentsSlice";
import { useFetchOrganizationsSlice } from "../slice/common/fetchOrganizationsSlice";

interface CommonStoreInterface
  extends ReturnType<typeof useFetchDepartmentsSlice>,
    ReturnType<typeof useFetchOrganizationsSlice> {}

export const useCommonStore = create<CommonStoreInterface>((...data) => ({
  ...useFetchDepartmentsSlice(...data),
  ...useFetchOrganizationsSlice(...data),
}));
