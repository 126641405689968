import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import Carousel from "react-material-ui-carousel";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ImageDisplay from "./imageDisplay";
import { GetImageRejectionReasons } from "../../../../domain/usages/data-entry/doctor-verification/get-image-rejection-reasons";
import { RejectImage } from "../../../../domain/usages/data-entry/doctor-verification/reject-image";
import { GetReadLink } from "../../../../domain/usages/get-read-link";
import RejectionReasonsList from "./RejectionReasonsList";
import { DoctorData } from "../../../../domain/models/doctor/doctorData";
import { Button, Grid } from "@mui/material";
import Swal from "sweetalert2";
import Status from "../../../../domain/enums/status";
import AutoCompleteComponent from "./AutoCompleteComponent";
import AddImageCommentModal from "./AddImageCommentModal";
import { useDoctorProfilerVerification } from "../../../../store/main/DoctorProfilerVerificationStore";
import { ImageType } from "../../../../domain/enums/image-types";

type ImageSelectionProps = {
  imageId: string;
  doctorId: string;
  selectedImageType: any;
};

type Props = {
  getImageRejectionReasons: GetImageRejectionReasons;
  rejectImage: RejectImage;
  getReadLink: GetReadLink;
  doctorDetails: DoctorData;
  handleImageDraggable: Function;
};

const DoctorImage: React.FC<Props> = ({
  getImageRejectionReasons,
  rejectImage,
  getReadLink,
  doctorDetails,
  handleImageDraggable,
}) => {
  const {
    fetchImageTypes,
    imageTypes,
    updateImageTypes,
    setDisableSubmitButton,
  } = useDoctorProfilerVerification();

  const [localDoctorDetails, setLocalDoctorDetails] =
    useState<DoctorData>(doctorDetails);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageID, setImageID] = useState("");
  const [comment, setComment] = useState<string>("");
  const [selectedImageType, setSelectedImageType] =
    useState<ImageSelectionProps>({
      imageId: "",
      doctorId: "",
      selectedImageType: "",
    });

  const [openImageCommentModal, setOpenImageCommentModal] =
    useState<boolean>(false);

  const [nonEmptySelections, setNonEmptySelections] = useState<string[]>([]);

  const handleTypeSelect = (id: string, imageId: string, type: string) => {
    setSelectedImageType({
      doctorId: id,
      imageId: imageId,
      selectedImageType: type,
    });

    if (type) {
      setNonEmptySelections((prev) => {
        const uniqueKey = `${imageId}`;
        return prev.includes(uniqueKey) ? prev : [...prev, uniqueKey];
      });
    }

    if (type !== ImageType.OTHERS) {
      updateImageTypes(id, imageId, type);
    }
  };

  const handleCommentChange = (value: string) => {
    setComment(value);
  };

  const handleSubmitComment = () => {
    setOpenImageCommentModal(false);
    updateImageTypes(
      selectedImageType.doctorId,
      selectedImageType.imageId,
      selectedImageType.selectedImageType,
      comment
    );
  };

  const handleRejectImage = (index: number) => {
    setImageID(localDoctorDetails.images[index]._id);
    setOpenDialog(true);
  };
  const loadingIndicator = (loading: boolean) => {
    if (loading) {
      Swal.fire({
        title: "Response Loading...",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  const handleRejectImageWithReasons = async (
    reason: string,
    otherReason: string
  ) => {
    loadingIndicator(true);
    const payload = {
      reasonCode: reason,
      rejectionReason: otherReason,
    };

    Object.keys(payload).forEach((key) => {
      if ((payload as any)[key].length === 0) {
        delete (payload as any)[key];
      }
    });
    setOpenDialog(false);
    let result = await rejectImage.reject(
      localDoctorDetails._id,
      imageID,
      payload
    );
    if (result.success) {
      loadingIndicator(false);
      Swal.fire(result.data.message, "", "success");
      const verification = {
        status: "rejected",
        rejectionReasons: [otherReason],
      };
      setLocalDoctorDetails((prevDetails: any) => ({
        ...prevDetails,
        images: prevDetails.images.map((img: any) =>
          img._id === imageID ? { ...img, verification } : img
        ),
      }));
      setNonEmptySelections((prev) => {
        const uniqueKey = `${imageID}`;
        return prev.includes(uniqueKey)
          ? prev.filter((key) => key !== uniqueKey)
          : prev;
      });
    } else {
      loadingIndicator(false);
      Swal.fire(result.message, "", "error");
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  const handleAddComment = () => {
    setOpenImageCommentModal(true);
  };

  useEffect(() => {
    if (selectedImageType.selectedImageType === ImageType.OTHERS) {
      handleAddComment();
    }
  }, [selectedImageType]);

  useEffect(() => {
    fetchImageTypes();
  }, []);

  useEffect(() => {
    const nonRejectedImagesCount = localDoctorDetails.images.filter(
      (image) => !image.verification || image.verification.status !== "rejected"
    ).length;

    if (nonEmptySelections.length === nonRejectedImagesCount) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  }, [nonEmptySelections, localDoctorDetails.images]);

  useEffect(() => {
    setLocalDoctorDetails(doctorDetails);
  }, [doctorDetails]);

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
      }}
    >
      <div>
        <Carousel
          sx={{
            width: "500px",
          }}
          animation="slide"
          swipe
          indicators
          navButtonsAlwaysVisible
          autoPlay={false}
          NextIcon={<ArrowCircleRightIcon />}
          PrevIcon={<ArrowCircleLeftIcon />}
          height="550px"
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "orange",
            },
          }}
          fullHeightHover
        >
          {localDoctorDetails.images?.map((image, index) => (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",

                  marginBottom: 15,
                }}
              >
                <Typography sx={{ fontSize: 16 }}>{image.type}</Typography>
                {image.verification?.status == Status.REJECTED ? (
                  <Typography sx={{ fontSize: 14 }} color="error">
                    <strong>IMAGE REJECTED</strong>
                  </Typography>
                ) : (
                  <Button
                    sx={{ fontSize: 14, fontWeight: 500 }}
                    variant="text"
                    onClick={() => handleRejectImage(index)}
                    color="error"
                  >
                    REJECT IMAGE
                  </Button>
                )}
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                }}
              >
                <ImageDisplay
                  getReadLink={getReadLink}
                  uuid={image.uuid}
                  id={localDoctorDetails._id}
                  imageHeight="350px"
                  imageWidth="100%"
                  handleImageDraggable={handleImageDraggable}
                />
              </div>
              {image.verification?.status !== "rejected" && (
                <Grid container spacing={1} mb={2} alignItems={"center"}>
                  <Grid item xs={12}>
                    <AutoCompleteComponent
                      currentSelectedValue=""
                      handleTypeSelect={handleTypeSelect}
                      imageTypeOptions={imageTypes}
                      id={localDoctorDetails?._id}
                      imageId={image?._id}
                    />
                  </Grid>

                  <AddImageCommentModal
                    open={openImageCommentModal}
                    handleClose={() => setOpenImageCommentModal(false)}
                    value={comment}
                    handleConfirm={() => handleSubmitComment()}
                    onInputChange={handleCommentChange}
                  />
                </Grid>
              )}
            </>
          ))}
        </Carousel>
      </div>
      <RejectionReasonsList
        openDialog={openDialog}
        handleVerify={handleRejectImageWithReasons}
        id={imageID}
        getRejectionReasons={getImageRejectionReasons}
        handleClose={handleCloseDialogBox}
      />
    </div>
  );
};

export default DoctorImage;
