import { OrganizationResponse } from "../../domain/models/organization-response";
import { FetchOrganizations } from "../../domain/usages/masters/organizations";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteFetchOrganizations implements FetchOrganizations {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}
  async fetch(): Promise<OrganizationResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,

      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.data;
  }
}
