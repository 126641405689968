import { DepartmentResponse } from "../../domain/models/departments-response";
import { FetchDepartments } from "../../domain/usages/masters/departments";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteFetchDepartments implements FetchDepartments {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}
  async fetch(): Promise<DepartmentResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,

      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.data;
  }
}
