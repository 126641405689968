import { Card, Typography } from "@mui/material";
import React from "react";
import { AdminDoctor } from "../../../domain/models/admin-doctor";

type Props = {
  doctorDetails: AdminDoctor;
  handleSelectDoctor: (id: string) => void;
  selectedDoctor: string;
};

const DoctorCard: React.FC<Props> = ({
  doctorDetails,
  handleSelectDoctor,
  selectedDoctor,
}) => {
  return (
    <>
      <Card
        sx={{
          border: "1px solid #D0CFCF",
          borderRadius: "12px",
          boxShadow: 1,
          mb: 2,
          cursor: "pointer",
        }}
        onClick={() => handleSelectDoctor(doctorDetails?._id)}
      >
        <div
          id="cardElement"
          className="subCardStyles"
          style={{
            overflow: "hidden",
            height: "76%",
            padding: "12px 16px 12px 16px",
            backgroundColor:
              doctorDetails?._id === selectedDoctor ? "#FFECE1" : "#FFF",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{ width: "85%", textTransform: "capitalize" }}
              className="inline"
            >
              <Typography fontSize={12}>{doctorDetails?.fullName}</Typography>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default DoctorCard;
