import React, { useEffect, useState } from "react";
import DoctorHeader from "./DoctorHeader";
import DoctorImage from "./DoctorImage";
import Typography from "@mui/material/Typography";
import DoctorBasicDetailsForm from "./DoctorBasicDetailsForm";
import { GetImageRejectionReasons } from "../../../../domain/usages/data-entry/doctor-verification/get-image-rejection-reasons";
import { RejectImage } from "../../../../domain/usages/data-entry/doctor-verification/reject-image";
import { GetDoctorByID } from "../../../../domain/usages/data-entry/doctor-verification/get-doctor-data-by-id";
import { SaveDoctorBasicDetails } from "../../../../domain/usages/data-entry/doctor-verification/save-doctor-basic-details";
import { VerifyDoctorContacts } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-contact";
import { GetReadLink } from "../../../../domain/usages/get-read-link";
import { DoctorData } from "../../../../domain/models/doctor/doctorData";
import FetchState from "../../../../domain/enums/fetch-state-type";
import DataSkeleton from "./loading/DataSkeleton";
import ImageDraggableDialog from "./ImageDraggableDialog";
import { VerifyDoctorAddress } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-address";
import { VerifyDoctorName } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-name";
import { VerifyDoctorQualification } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-qualification";
import { VerifyDoctorSpeciality } from "../../../../domain/usages/data-entry/doctor-verification/verify-doctor-speciality";
import { Qualification } from "../../../../domain/models/qualification";
import { Speciality } from "../../../../domain/models/speciality";
import { GetAddressByPincode } from "../../../../domain/usages/get-address-by-pincode";

type Props = {
  getDoctorByID: GetDoctorByID;
  getImageRejectionReasons: GetImageRejectionReasons;
  rejectImage: RejectImage;
  saveDoctorDetails: SaveDoctorBasicDetails;
  verifyDoctorDetails: VerifyDoctorContacts;
  getReadLink: GetReadLink;
  selectedDoctor: DoctorData;
  reloadList: Function;
  verifyAddress: VerifyDoctorAddress;
  specialities: Speciality[];
  qualifications: Qualification[];
  verifyName: VerifyDoctorName;
  verifyQualification: VerifyDoctorQualification;
  verifySpeciality: VerifyDoctorSpeciality;
  getAddress: GetAddressByPincode;
};

const DoctorDetails: React.FC<Props> = ({
  getDoctorByID,
  getImageRejectionReasons,
  rejectImage,
  saveDoctorDetails,
  verifyDoctorDetails,
  getReadLink,
  selectedDoctor,
  reloadList,
  verifyAddress,
  specialities,
  qualifications,
  verifyName,
  verifyQualification,
  verifySpeciality,
  getAddress,
}) => {
  const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
  const [doctorDetails, setDoctorDetails] = useState<DoctorData>(
    {} as DoctorData
  );
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [imageLink, setImageLink] = useState("");

  const getDoctorDetails = async (id: string) => {
    if (id) {
      try {
        setFetchState(FetchState.LOADING);
        let result = await getDoctorByID.get(id);
        if (result) {
          setDoctorDetails(result.data);
          setFetchState(FetchState.SUCCESS);
        }
      } catch (err) {
        setFetchState(FetchState.ERROR);
      }
    }
  };
  const reloadDetails = () => {
    getDoctorDetails(selectedDoctor._id);
  };

  useEffect(() => {
    if (selectedDoctor._id) {
      getDoctorDetails(selectedDoctor._id);
    }
  }, [selectedDoctor._id]);

  const handleImageDraggable = (data: string) => {
    setImageLink(data);
    setOpenImageDialog(!openImageDialog);
  };
  const handleCloseImageDialog = () => {
    setOpenImageDialog(!openImageDialog);
  };

  return (
    <div>
      {fetchState === FetchState.LOADING && (
        <>
          <div style={{ margin: 20 }}>
            <DataSkeleton />
          </div>
        </>
      )}
      {fetchState === FetchState.ERROR && (
        <>
          <Typography color="error">something went wrong</Typography>
        </>
      )}
      {fetchState === FetchState.SUCCESS && (
        <>
          <DoctorHeader doctorDetails={doctorDetails} />
          <div style={{ marginTop: 50 }}>
            <DoctorImage
              getImageRejectionReasons={getImageRejectionReasons}
              rejectImage={rejectImage}
              getReadLink={getReadLink}
              doctorDetails={doctorDetails}
              handleImageDraggable={handleImageDraggable}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              Basic Details:
            </Typography>
            <DoctorBasicDetailsForm
              saveDoctorDetails={saveDoctorDetails}
              verifyDoctorDetails={verifyDoctorDetails}
              doctorDetails={doctorDetails}
              reloadDetails={reloadDetails}
              reloadList={reloadList}
              verifyAddress={verifyAddress}
              specialities={specialities}
              qualifications={qualifications}
              verifyName={verifyName}
              verifyQualification={verifyQualification}
              verifySpeciality={verifySpeciality}
              getAddress={getAddress}
            />
          </div>
          {openImageDialog && (
            <div style={{ width: "300px", height: "300px" }}>
              <ImageDraggableDialog
                imageLink={imageLink}
                handleClose={handleCloseImageDialog}
                iconSize="medium"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DoctorDetails;
