import { Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import DoctorSummaryCard from "../../components/data-entry/doctorProfilerVerifcation/DoctorSummaryCard";
import PersonIcon from "@mui/icons-material/Person";
import { FetchVerificationCounts } from "../../../domain/usages/data-entry/doctor-verification/fetch-verification-counts";
import { VerificationCounts } from "../../../domain/models/data-entry/verificationCounts";
import FetchState from "../../../domain/enums/fetch-state-type";
import VerifiedIcon from "@mui/icons-material/Verified";
import { HourglassBottom, Image } from "@mui/icons-material";

type Props = {
  doctorVerficationCounts: FetchVerificationCounts;
};

const DoctorCountSummary: React.FC<Props> = ({ doctorVerficationCounts }) => {
  const [countSummary, setCountSummary] = useState<VerificationCounts>(
    {} as VerificationCounts
  );
  const [fetchState, setFetchState] = useState<FetchState>(FetchState.DEFAULT);

  const fetchVerificationCounts = async () => {
    setFetchState(FetchState.LOADING);
    let result = await doctorVerficationCounts.fetch();
    if (result.success) {
      setCountSummary(result.data);
      setFetchState(FetchState.SUCCESS);
    }
  };

  useEffect(() => {
    fetchVerificationCounts();
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={6} m={10}>
        <Box display="flex" gap={6} alignItems="center">
          <DoctorSummaryCard
            icon={<PersonIcon sx={{ fontSize: "4rem" }} />}
            loading={fetchState === FetchState.LOADING}
            cardTitle="Verified Doctors"
            cardData={countSummary.verifiedDoctorsCount}
            cardLabel="Doctors"
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <DoctorSummaryCard
            icon={<VerifiedIcon sx={{ fontSize: "4rem" }} />}
            loading={fetchState === FetchState.LOADING}
            cardTitle="Today's Verified Doctors"
            cardData={countSummary.todaysVerifiedCount}
            cardLabel="Doctors"
          />
        </Box>
        <Box display="flex" gap={6} alignItems="center">
          <DoctorSummaryCard
            icon={<HourglassBottom sx={{ fontSize: "4rem" }} />}
            loading={fetchState === FetchState.LOADING}
            cardTitle="Pending Doctors"
            cardData={countSummary.pendingDoctors}
            cardLabel="Doctors"
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <DoctorSummaryCard
            icon={<Image sx={{ fontSize: "4rem" }} />}
            loading={fetchState === FetchState.LOADING}
            cardTitle="Pending Doctors With Images"
            cardData={countSummary.pendingDoctorsWithImages}
            cardLabel="Doctors"
          />
        </Box>
      </Box>
    </>
  );
};

export default DoctorCountSummary;
