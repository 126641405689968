import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import { RemoteFetchDoctors } from "../../../data/usecases/data-entry/doctor-verification/remote-fetch-doctors";
import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { Doctor } from "../../../domain/models/doctor";
import { FetchedDoctorsDetailsRequestMetaData } from "../../../presentation/components/admin/doctor-listing";
import { AdminDoctor } from "../../../domain/models/admin-doctor";
import { FilterLabels } from "../../../domain/enums/filter-labels";
import { FetchDoctors } from "../../../domain/usages/data-entry/doctor-verification/fetch-doctors";
import { useDoctorStore } from "../../main/DoctorStore";

interface FetchDoctorSliceInterface {
  fetchDoctors: Function;
  doctorsList: AdminDoctor[];
  fetchDoctorsListState: FetchState;
  onSelectedDoctor: Function;
  fetchDoctorsMetaData: FetchedDoctorsDetailsRequestMetaData;
}

const initialState = {
  doctorsList: [],
  fetchDoctorsListState: FetchState.DEFAULT,
  fetchDoctorsMetaData: {} as FetchedDoctorsDetailsRequestMetaData,
};

export const useFetchDoctorsSlice: StateCreator<FetchDoctorSliceInterface> = (
  set,
  get
) => {
  return {
    ...initialState,
    fetchDoctors: async (
      page: number,
      scrolled: boolean,
      filterType: string,
      filterValue: string
    ) => {
      let filter: FetchDoctors.Params = {
        page: page,
        limit: 10,
      };

      if (filterType === FilterLabels.DOCTOR_NAME)
        filter = {
          ...filter,
          name: filterValue,
        };
      else if (filterType === FilterLabels.DOCTOR_ID) {
        filter = {
          ...filter,
          id: filterValue,
        };
      } else if (filterType === FilterLabels.MOBILE_NUMBER) {
        filter = {
          ...filter,
          mobile: filterValue,
        };
      }

      const storage = LocalJsonStorage.getInstance();
      const token = storage.get(AUTH_TOKEN);
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
      });

      const fetchDoctorsList = new RemoteFetchDoctors(
        `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_DOCTORS}`,
        axiosHttpClient
      );
      try {
        if (!scrolled) {
          set({ fetchDoctorsListState: FetchState.LOADING });
        }
        let result = await fetchDoctorsList.fetch(filter);
        if (result.success) {
          set({
            fetchDoctorsMetaData: {
              hasNextPage: result.data.hasNextPage,
              hasPreviousPage: result.data.hasPreviousPage,
              limit: result.data.limit,
              page: result.data.page,
              total: result.data.total,
              totalPages: result.data.totalPages,
            },
          });

          if (scrolled) {
            set({ doctorsList: [...get().doctorsList, ...result.data.result] });
          } else {
            set({ doctorsList: result.data.result });

            await useDoctorStore
              .getState()
              .fetchDoctorById(get().doctorsList[0]._id);
          }
          set({ fetchDoctorsListState: FetchState.SUCCESS });
        }
      } catch (err) {
        set({ fetchDoctorsListState: FetchState.ERROR });
      }
    },
    onSelectedDoctor: async (id: string) => {
      await useDoctorStore.getState().fetchDoctorById(id);
    },
  };
};
