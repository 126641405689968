import {
  Box,
  Button,
  Slide,
  Stack,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Organization } from "../../../../domain/models/organization-response";
import { Department } from "../../../../domain/models/departments-response";
import { useCommonStore } from "../../../../store/main/CommonStore";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  handleClose: () => void;
  handleApplyFilter: (
    selectedOrganization: Organization,
    selectedDepartment: Department
  ) => void;
  handleResetFilters: Function;
  handleOrganizationChange: (newValue: any) => void;
  handleDepartmentChange: (newValue: any) => void;
  selectedOrganization: Organization | null;
  selectedDepartment: Department | null;
};

const ImageFiltersModal: React.FC<Props> = ({
  open,
  handleClose,
  handleApplyFilter,
  handleOrganizationChange,
  handleDepartmentChange,
  selectedOrganization,
  selectedDepartment,
  handleResetFilters,
}) => {
  const { fetchDepartments, fetchOrganizations, organizations, departments } =
    useCommonStore();

  useEffect(() => {
    fetchDepartments();
    fetchOrganizations();
  }, []);

  return (
    <>
      <Box
        sx={{
          p: 5,
        }}
      >
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose}
          sx={{ p: 5 }}
        >
          <Stack direction="row" justifyContent={"space-between"} px={5}>
            <DialogTitle>Apply Filters</DialogTitle>

            <Button
              sx={{ maxWidth: "30px", float: "right", textAlign: "right" }}
              onClick={() => handleResetFilters()}
            >
              <RestartAltIcon />
              Reset
            </Button>
          </Stack>
          <DialogContent
            sx={{
              p: 5,
              minWidth: "400px !important",
            }}
          >
            <Box>
              <Autocomplete
                options={organizations}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  );
                }}
                value={selectedOrganization}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Organization"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                )}
                onChange={(event: React.SyntheticEvent, newValue: any) => {
                  handleOrganizationChange(newValue); // l
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
              />
              <Autocomplete
                options={departments}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  );
                }}
                value={selectedDepartment}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Department"
                    variant="outlined"
                    fullWidth
                    sx={{ minWidth: "150px" }}
                  />
                )}
                onChange={(event: React.SyntheticEvent, newValue: any) => {
                  handleDepartmentChange(newValue); // l
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            <Button onClick={() => handleClose()}>Cancel</Button>
            <Button
              sx={{ color: "#fff" }}
              variant="contained"
              onClick={() => {
                if (selectedOrganization && selectedDepartment) {
                  handleApplyFilter(selectedOrganization, selectedDepartment);
                }
              }}
              type={"submit"}
            >
              Apply Filters
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ImageFiltersModal;
