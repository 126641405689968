import Expand from "@mui/icons-material/OpenWith";
import {
  IconButton,
  Tooltip,
  CardMedia,
  Card,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VerifiedIcon from "@mui/icons-material/Verified";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { Image } from "../../../domain/models/doctor/image";

type Props = {
  image: Image;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  setOpenImage: React.Dispatch<React.SetStateAction<boolean>>;
};

const ImageThumbnails: React.FC<Props> = ({
  image,
  setImageUrl,
  setOpenImage,
}) => {
  return (
    <>
      <div>
        <br />
        <div>
          <>
            <Card
              sx={{
                maxWidth: 300,
                margin: "auto",
                position: "relative",
                overflow: "visible",
                border: "1px solid #f1f1f1",
                mb: 2,
              }}
            >
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{}}
              >
                <Box p={2}>
                  <Typography>Image type: {image?.type}</Typography>
                  {image?.verification !== null && (
                    <Stack direction="row">
                      <Typography>
                        Status:{" "}
                        <span
                          style={{
                            fontWeight: 700,
                            textTransform: "capitalize",
                          }}
                        >
                          {image?.verification?.status}
                        </span>
                      </Typography>
                      {image?.verification?.status === "verified" ? (
                        <VerifiedIcon color="success" />
                      ) : (
                        <UnpublishedIcon color="error" />
                      )}
                    </Stack>
                  )}
                  <>
                    {image?.verification?.rejectionReasons &&
                      image?.verification?.rejectionReasons?.length > 0 && (
                        <Typography>
                          Rejection Reason :{" "}
                          {image?.verification?.rejectionReasons
                            ?.map(
                              (reason, index, array) =>
                                reason.reason +
                                (index < array.length - 1 ? ", " : "")
                            )
                            .join("")}
                        </Typography>
                      )}
                  </>
                </Box>

                <div>
                  <Tooltip title="Expand Image" placement="top" arrow>
                    <IconButton
                      color="warning"
                      onClick={() => {
                        setImageUrl(image?.url);
                        setOpenImage(true);
                      }}
                    >
                      <Expand />
                    </IconButton>
                  </Tooltip>
                  <a
                    href={image?.url}
                    rel="noreferrer"
                    target="_blank"
                    download={false}
                  >
                    <Tooltip
                      title="Open Image in new tab"
                      placement="top"
                      arrow
                    >
                      <IconButton color="warning">
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </a>
                </div>
              </Stack>
              <CardMedia
                onClick={() => {
                  setImageUrl(image?.url);
                  setOpenImage(true);
                }}
                sx={{ height: 250 }}
                image={image?.url}
              />
            </Card>
          </>
        </div>
      </div>
    </>
  );
};

export default ImageThumbnails;
