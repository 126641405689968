import React from "react";
import CenteredStack from "../../../ga-components/centered-stack";
import NotFoundArtwork from "../../../ga-components/NotFound";
import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
}
const NotAvailable: React.FC<Props> = ({ title }) => {
  return (
    <div>
      <CenteredStack
        sx={{
          height: "250px",
          justifyContent: "center !important",
          border: "1px solid #f0f0f0",
          borderRadius: "12px",
        }}
      >
        <Box p={5}>
          <NotFoundArtwork size={200} />
          <Typography fontSize={"30px"} fontWeight={600}>
            No {title} available.
          </Typography>
        </Box>
      </CenteredStack>
    </div>
  );
};

export default NotAvailable;
