import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImageSkeleton from "../components/data-entry/doctorImageVerification/ImageSkeleton";
import { NotFoundComponent } from "../components/data-entry/doctorImageVerification/NotFoundComponent";
import ImageFiltersModal from "../components/data-entry/doctorImageVerification/ImageFilterModal";
import ImageVerificationFilterComponent from "../components/data-entry/doctorImageVerification/FilterComponent";
import ImageListingCard from "../components/data-entry/doctorImageVerification/DoctorImageVerificationCard";
import { useDoctorProfilerVerification } from "../../store/main/DoctorProfilerVerificationStore";
import { Organization } from "../../domain/models/organization-response";
import { Department } from "../../domain/models/departments-response";
import FetchState from "../../domain/enums/fetch-state-type";

const DoctorImageVerificationPage: React.FC = () => {
  const {
    fetchUnverifiedImages,
    fetchUnverifiedImagesState,
    unverifiedImages,
    totalImages,
    totalPages,
  } = useDoctorProfilerVerification();
  let loadingOccurences = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);
  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(null);
  const [pageValue, setPageValue] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(9);
  const [openFilterModal, setOpenFilterModal] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");

  let params = {
    limit: limit ? limit : 9,
    page: pageValue ? pageValue : 1,
    orgCode: selectedOrganization?.code,
    deptCode: selectedDepartment?.code,
    id: "",
    source: "",
    fullName: "",
  };
  const FetchUnverifiedImages = async () => {
    setLoading(true);

    const { page, limit, id, orgCode, deptCode } = params;

    await fetchUnverifiedImages(page, limit, id, orgCode, deptCode);
    setLoading(false);
    setOpenFilterModal(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOrganizationChange = (value: Organization) => {
    setSelectedOrganization(value);
  };

  const handleDepartmentChange = (value: Department) => {
    setSelectedDepartment(value);
  };

  const handlePageChange = (value: number) => {
    setPageValue(value);
  };

  const handleLimitChange = (value: number) => {
    setLimit(value);
  };

  const handleApplyFilter = () => {
    FetchUnverifiedImages();
  };

  const handleRemoveFilter = () => {
    params.orgCode = "";
    params.deptCode = "";
    setSelectedDepartment(null);
    setSelectedOrganization(null);
    setPageValue(1);
    setLimit(9);

    FetchUnverifiedImages();
  };

  const handleSearch = (value: string) => {
    params.id = value;
    FetchUnverifiedImages();
  };

  const handleSubmitCallBack = () => {
    FetchUnverifiedImages();
    params.id = "";
    setInputValue("");
  };

  useEffect(() => {
    FetchUnverifiedImages();
  }, []);

  useEffect(() => {
    FetchUnverifiedImages();
  }, [pageValue, limit]);

  useEffect(() => {
    setLoading(fetchUnverifiedImagesState == FetchState.LOADING);
  }, [fetchUnverifiedImagesState]);

  return (
    <>
      <ImageVerificationFilterComponent
        totalPages={totalPages}
        pageValue={pageValue}
        setPageValue={(value: number) => handlePageChange(value)}
        limit={limit}
        setLimit={(value: number) => handleLimitChange(value)}
        totalImages={totalImages}
        handleSearch={(value: string) => handleSearch(value)}
        handleOpenFilterModal={() => setOpenFilterModal(true)}
        inputValue={inputValue}
        handleInputChange={(event: any) => handleInputChange(event)}
        loading={loading}
      />
      {loading ? (
        <Grid container spacing={2}>
          {loadingOccurences?.map((index: number) => (
            <Grid key={index} item xs={4} mb={3}>
              <ImageSkeleton key={index} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <div>
          {!unverifiedImages.length && (
            <NotFoundComponent handleClick={handleRemoveFilter} />
          )}
          <ImageListingCard
            doctors={unverifiedImages}
            handleSubmitCallBack={handleSubmitCallBack}
          />
          <ImageFiltersModal
            open={openFilterModal}
            handleClose={() => setOpenFilterModal(false)}
            handleApplyFilter={handleApplyFilter}
            handleResetFilters={handleRemoveFilter}
            handleOrganizationChange={handleOrganizationChange}
            handleDepartmentChange={handleDepartmentChange}
            selectedOrganization={selectedOrganization}
            selectedDepartment={selectedDepartment}
          />
        </div>
      )}
    </>
  );
};

export default DoctorImageVerificationPage;
