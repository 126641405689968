import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import { generateOptions } from "../../../../utils/Helpers";

interface Props {
  totalPages: number;
  totalImages: number;
  pageValue: number;
  setPageValue: (value: number) => void;
  limit: number;
  setLimit: (value: number) => void;
  handleOpenFilterModal: () => void;
  handleSearch: (inputValue: string) => void;
  inputValue: string;
  handleInputChange: (event: any) => void;
  loading: boolean;
}

const ImageVerificationFilterComponent: React.FC<Props> = ({
  totalPages,
  pageValue,
  setPageValue,
  limit,
  setLimit,
  totalImages,
  handleSearch,
  handleOpenFilterModal,
  inputValue,
  handleInputChange,
  loading,
}) => {
  const limitOptions = generateOptions();

  const isValidMongoId = (id: string) => /^[a-f\d]{24}$/i.test(id);

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        p={3}
        pt={8}
        gap={2}
      >
        {loading ? (
          <Skeleton variant="rectangular" width={200} height={30} />
        ) : (
          <Box>
            <Stack direction={"row"}>
              <TextField
                label="Doctor ID"
                value={inputValue}
                placeholder="Enter Doctor Id"
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                error={!isValidMongoId(inputValue) && inputValue.length > 0}
                helperText={
                  !isValidMongoId(inputValue) && inputValue.length > 0
                    ? "Invalid ID"
                    : ""
                }
              />
              <IconButton
                disabled={!isValidMongoId(inputValue && inputValue)}
                onClick={() => handleSearch(inputValue)}
              >
                <Tooltip title="Search">
                  <SearchIcon color="primary" />
                </Tooltip>
              </IconButton>
              <IconButton type="button" onClick={handleOpenFilterModal}>
                <Tooltip title="Apply Filters">
                  <TuneIcon color="primary" sx={{ fontSize: "30px" }} />
                </Tooltip>
              </IconButton>
            </Stack>
          </Box>
        )}
        {loading ? (
          <Skeleton variant="rectangular" width={100} height={30} />
        ) : (
          <Typography>Total Pages : {totalPages} </Typography>
        )}
        {loading ? (
          <Skeleton variant="rectangular" width={100} height={30} />
        ) : (
          <Typography>Total Images : {totalImages} </Typography>
        )}
        {loading ? (
          <Skeleton variant="rectangular" width={100} height={30} />
        ) : (
          <Stack direction="row" alignItems={"center"} gap={2}>
            <TextField
              onChange={(event: any) => {
                setPageValue(event.target.value);
              }}
              value={pageValue}
              label="Jump to page"
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel>Limit</InputLabel>
              <Select
                onChange={(event: any) => setLimit(event.target.value)}
                value={limit}
              >
                {limitOptions.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default ImageVerificationFilterComponent;
