import React from "react";
import {
  Autocomplete,
  TextField,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/system";

type ImageType = {
  key: string;
  value: string;
};

type Props = {
  id: string;
  imageId: string;
  imageTypeOptions: ImageType[];
  currentSelectedValue: string;
  handleTypeSelect: (id: string, imageId: string, value: string) => void;
  disabled?: boolean;
};

const AutoCompleteComponent: React.FC<Props> = ({
  imageTypeOptions,
  handleTypeSelect,
  id,
  imageId,
  disabled,
}) => {
  return (
    <div>
      <Box mt={1}>
        <InputLabel
          sx={{
            fontSize: "1rem",
            fontWeight: 500,
          }}
          shrink
        >
          Select Image Type
        </InputLabel>
        <FormControl sx={{ minWidth: "100%" }}>
          <Autocomplete
            options={imageTypeOptions}
            getOptionLabel={(option: ImageType) => `${option.value}`}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e: React.SyntheticEvent, value: any) => {
              handleTypeSelect(id, imageId, value?.key ?? "");
            }}
            disableClearable
            sx={{
              fontSize: "0.90rem !important",
            }}
            disabled={disabled}
            renderInput={(params) => <TextField {...params} />}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
          />
        </FormControl>
      </Box>
    </div>
  );
};

const GroupItems = styled("ul")({
  padding: 0,
});

export default AutoCompleteComponent;
