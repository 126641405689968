import { StateCreator } from "zustand";
import FetchState from "../../../domain/enums/fetch-state-type";
import {
  AUTH_HEADER_VERIFICATION,
  AUTH_TOKEN,
  DOCTOR_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import {
  ImageTypeResponseData,
  ImageTypeResponse,
} from "../../../domain/models/data-entry/imageTypes";
import { RemoteFetchImageType } from "../../../data/usecases/data-entry/doctor-verification/remote-fetch-image-types";

interface fetchImageTypesSliceInterface {
  fetchImageTypes: Function;
  imageTypes: ImageTypeResponseData[];
  fetchImageTypesState: FetchState;
}

const initialState = {
  imageTypes: [],
  fetchImageTypesState: FetchState.DEFAULT,
};

export const useFetchImageTypesSlice: StateCreator<
  fetchImageTypesSliceInterface
> = (set, get) => {
  return {
    ...initialState,
    fetchImageTypes: async () => {
      const storage = LocalJsonStorage.getInstance();
      const token = storage.get(AUTH_TOKEN);
      const axiosHttpClient = AxiosHttpClient.getInstance();
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER_VERIFICATION]: `Bearer ${atob(token)}`,
      });
      console.log(atob(token));

      const fetchImageTypes = new RemoteFetchImageType(
        `${DOCTOR_SERVICE_API_URL}${Endpoints.FETCH_IMAGE_TYPES}`,
        axiosHttpClient
      );

      try {
        set({ fetchImageTypesState: FetchState.LOADING });

        let result: ImageTypeResponse = await fetchImageTypes.fetch({});
        if (result.success) {
          set({ imageTypes: result.data });

          set({ fetchImageTypesState: FetchState.SUCCESS });
        }
      } catch (err) {
        set({ fetchImageTypesState: FetchState.ERROR });
      }
    },
  };
};
