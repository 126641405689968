import {
  Box,
  Container,
  Fade,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDoctorStore } from "../../store/main/DoctorStore";
import FetchState from "../../domain/enums/fetch-state-type";
import DoctorsList from "../components/admin/doctor-listing";
import { FilterLabels } from "../../domain/enums/filter-labels";
import { CopyAllOutlined } from "@mui/icons-material";
import { DoctorDetailsSkeleton } from "../components/admin/doctor-details-skeleton";
import DoctorDetailsTabs from "../components/admin/doctor-details-tabs";
import DoctorPersonalDetails from "../components/admin/doctor-personal-details";
import DoctorMappingDetails from "../components/admin/doctor-mapping-details";
import ImageThumbnails from "../components/admin/image-thumbnails";
import NotAvailable from "../components/admin/common/not-available";
import ImageDraggableDialogBox from "../components/admin/common/image-draggable-dialog-box";

export const DoctorDetailsPage: React.FC = () => {
  const {
    fetchDoctors,
    doctorsList,
    fetchDoctorByIdState,
    selectedDoctor,
    onSelectedDoctor,
    fetchDoctorsMetaData,
  } = useDoctorStore();

  const [searchQuery, setSearchQuery] = useState<string | undefined>("");
  const [filterType, setFilterType] = useState<string>("id");
  const [idCopied, setIdCopied] = React.useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const handleChangeSearch = (selectType: string, searchedCustomer: string) => {
    setFilterType(selectType);
    setSearchQuery(searchedCustomer);
  };

  const handleResetCallBack = () => {
    if (filterType !== FilterLabels.DOCTOR_ID) {
      setSearchQuery("");
    }
  };

  useEffect(() => {
    console.log(searchQuery, filterType);
    fetchDoctors(1, false, filterType, searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    fetchDoctors(1, false);
  }, []);

  return (
    <Box sx={{ overflowX: "hidden" }} mt={10}>
      {openImage && (
        <ImageDraggableDialogBox
          setOpenImage={setOpenImage}
          openImage={openImage}
          imageUrl={imageUrl}
        />
      )}
      <Grid container>
        <Grid item xs={2.5} sm={2.5} md={3} lg={3} ml={2}>
          <DoctorsList
            handleChangeSearch={handleChangeSearch}
            searchedDoctor={searchQuery}
            doctors={doctorsList}
            fetchMoreDoctors={(page: number, limit: number) =>
              fetchDoctors(page, limit, searchQuery)
            }
            metaData={fetchDoctorsMetaData}
            selectDoctor={(DoctorId: string) => onSelectedDoctor(DoctorId)}
            handleResetCallback={() => handleResetCallBack()}
            selectedDoctor={selectedDoctor?._id}
            filterType={filterType}
          />
        </Grid>

        <Grid item xs={7} sm={7} md={5.5} lg={5.5}>
          <Container>
            {fetchDoctorByIdState === FetchState.SUCCESS &&
              fetchDoctorsMetaData &&
              fetchDoctorsMetaData?.total > 0 && (
                <>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography color="gray" component="div">
                      #{selectedDoctor?._id}
                    </Typography>
                    <div>
                      <IconButton
                        onClick={async () => {
                          await navigator.clipboard.writeText(
                            selectedDoctor?._id
                          );
                          setIdCopied(true);
                          setTimeout(() => {
                            setIdCopied(false);
                          }, 500);
                        }}
                      >
                        <CopyAllOutlined />
                      </IconButton>
                    </div>
                    <Fade in={idCopied}>
                      <Typography color="green">Id Copied</Typography>
                    </Fade>
                  </Box>
                  <DoctorDetailsTabs
                    tabs={[
                      {
                        label: "Personal Details",
                        content: (
                          <DoctorPersonalDetails
                            doctorDetails={selectedDoctor}
                          />
                        ),
                      },
                      {
                        label: `Mappings (${selectedDoctor?.mappings?.length})`,
                        content: (
                          <DoctorMappingDetails
                            mappings={selectedDoctor.mappings}
                          />
                        ),
                      },
                    ]}
                  />
                </>
              )}

            {fetchDoctorByIdState === FetchState.LOADING && (
              <DoctorDetailsSkeleton />
            )}
          </Container>
        </Grid>
        <Grid item xs={2.5} sm={2.5} md={3} lg={3}>
          {selectedDoctor?.images?.length > 0 ? (
            <Stack mt={13}>
              {fetchDoctorByIdState === FetchState.SUCCESS && (
                <>
                  {selectedDoctor?.images?.map((image, ind) => (
                    <ImageThumbnails
                      setImageUrl={setImageUrl}
                      setOpenImage={setOpenImage}
                      key={image._id}
                      image={image}
                    />
                  ))}
                </>
              )}
              {fetchDoctorByIdState === FetchState.SUCCESS &&
                selectedDoctor?.images?.length === 0 && (
                  <Box m={2}>
                    <NotAvailable title="Image" />
                  </Box>
                )}
              {fetchDoctorByIdState === FetchState.LOADING && (
                <Skeleton width={500} height={100} />
              )}
            </Stack>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
