import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

type Props = {
  cardTitle: string;
  cardData: string | number;
  cardLabel: string;
  loading: boolean;
  icon: React.ReactNode;
};

const DoctorSummaryCard: React.FC<Props> = ({
  cardData,
  cardLabel,
  cardTitle,
  loading,
  icon,
}) => {
  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        alignItems="center"
        width={"300px"}
      >
        <div
          style={{
            padding: "1rem",
            backgroundColor: "#FFF3E9",
            borderRadius: "12px",
          }}
        >
          {icon}
        </div>
        <Box display="flex" flexDirection="row" alignItems="center">
          <div>
            <Typography
              sx={{
                color: "#9FB4C7",
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              {cardTitle}
            </Typography>
            <br />
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <Typography
                fontWeight={600}
                fontSize={32}
                sx={{ color: "#4D4D4D" }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#4D4D4D" }} />
                ) : (
                  cardData
                )}
              </Typography>
              <Typography color="#9FB4C7" fontSize={16}>
                {cardLabel}
              </Typography>
            </Box>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default DoctorSummaryCard;
