import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DoctorCard from "./doctor-card";
import { useDebounce } from "../../../hooks/useDebounce";
import SkeletonCard from "../common/skeleton/SkeletonCard";
import { AdminDoctor } from "../../../domain/models/admin-doctor";

const skelArr: any = [];

for (let i = 0; i <= 16; i++) {
  skelArr.push(i);
}

export type FetchedDoctorsDetailsRequestMetaData = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  limit: number;
  page: number;
  total: number;
  totalPages: number;
};

type ValueType = "mobile" | "id" | "name";

type Props = {
  doctors: AdminDoctor[];
  metaData: FetchedDoctorsDetailsRequestMetaData;
  selectedDoctor: string;
  selectDoctor: (value: string) => void;
  fetchMoreDoctors: (page: number, limit: number, refresh: boolean) => void;
  searchedDoctor: string | undefined;
  handleChangeSearch: Function;
  filterType: string;
  handleResetCallback: Function;
};

const DoctorsList: React.FC<Props> = ({
  doctors,
  metaData,
  selectDoctor,
  selectedDoctor,
  fetchMoreDoctors,
  handleChangeSearch,
  handleResetCallback,
}) => {
  const scrollDiv = useRef<HTMLHeadingElement>(null);
  const [selectedType, setSelectedType] = useState<ValueType>("id");
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string>("");
  const debouncedInputValue = useDebounce(inputValue, 500);
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setError("");
  };

  const handleTypeChange = (event: any) => {
    setSelectedType(event.target.value);
    setInputValue("");
    setError("");
  };

  useEffect(() => {
    if (debouncedInputValue === "") {
      setError("");
      handleChangeSearch(selectedType, debouncedInputValue);
      return;
    }

    let isValid = true;
    switch (selectedType) {
      case "mobile":
        isValid = inputValue.length === 10;
        break;
      case "id":
        isValid = /^[a-f\d]{24}$/.test(inputValue);
        break;
      case "name":
        isValid = typeof inputValue === "string";
        break;
      default:
        isValid = typeof inputValue === "string";
        break;
    }

    if (!isValid) {
      setError("Invalid value");
    } else {
      setError("");
      handleChangeSearch(selectedType, debouncedInputValue);
    }
  }, [selectedType, debouncedInputValue, handleChangeSearch, inputValue]);

  useEffect(() => {
    if (inputValue === "") {
      handleResetCallback();
    }
  }, [inputValue, handleResetCallback]);

  return (
    <Box
      ref={scrollDiv}
      id="scrollableDiv"
      className="stylishScroll"
      sx={{
        paddingRight: "24px",
        height: "92vh",
        background: "white",
        overflowY: "auto",
        position: "fixed",
      }}
    >
      <form>
        <Stack zIndex={1} bgcolor={"#fff"} position={"sticky"} top="0px">
          <Stack justifyContent={"space-between"} direction={"row"}>
            <Box sx={{ width: "100%" }}>
              <Select
                fullWidth
                value={selectedType}
                onChange={(event: SelectChangeEvent) => handleTypeChange(event)}
                size="small"
                sx={{ height: "50px" }}
              >
                <MenuItem value="id">Doctor ID</MenuItem>
                <MenuItem value="name">Doctor Name</MenuItem>
                <MenuItem value="mobile">Mobile Number</MenuItem>
              </Select>
            </Box>
          </Stack>
          <>
            <Stack
              sx={{
                borderRadius: "12px",
                position: "sticky ",
                top: 10,
                zIndex: 10,
                my: 2,
                backgroundColor: "#fff !important",
              }}
              direction="row"
              alignItems={"center"}
            >
              <FormControl>
                <TextField
                  placeholder={`Search Doctor`}
                  value={inputValue}
                  onChange={handleInputChange}
                  error={!!error}
                  helperText={error}
                  required
                  fullWidth
                  sx={{ width: "100%", flexGrow: 1 }}
                />
              </FormControl>
              <Tooltip title="Search">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        </Stack>
      </form>
      <br />
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "relative",
            zIndex: 0,
            marginBottom: "10%",
          }}
        >
          {metaData?.page ? (
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={doctors?.length}
              next={() => {
                fetchMoreDoctors(metaData.page + 1, metaData.limit, true);
              }}
              hasMore={metaData.hasNextPage ? true : false}
              endMessage={
                <Typography textAlign="center" marginTop={2}>
                  That's it for now
                </Typography>
              }
              loader={<SkeletonCards />}
            >
              {doctors.map((item: AdminDoctor, index: number) => (
                <DoctorCard
                  selectedDoctor={selectedDoctor}
                  handleSelectDoctor={selectDoctor}
                  doctorDetails={item}
                  key={index}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <div>
              <SkeletonCards />
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};

export default DoctorsList;

const SkeletonCards = () => {
  let skelArr: any = [];

  for (let i = 0; i <= 16; i++) {
    skelArr.push(i);
  }

  return (
    <>
      {skelArr.map((val: number, ind: number) => {
        return (
          <div
            key={val}
            style={{
              maxWidth: "100%",
              paddingTop: "22px",
              borderColor: "#94D2BD",
            }}
          >
            <SkeletonCard key={val} />
          </div>
        );
      })}
    </>
  );
};
